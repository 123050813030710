import { render } from 'react-dom'
import React, { useState, useEffect } from 'react'
import { useTransition, animated, config } from 'react-spring'
import '.././App.css';
import img1 from "../img/carosello1.png"
import img2 from "../img/carosello2.png"
import img3 from "../img/carosello3.png"
import img4 from "../img/carosello4.png"
import img5 from "../img/carosello5.png"
import img6 from "../img/carosello6.png"
import img7 from "../img/carosello7.png"
import img8 from "../img/carosello8.png"
import img9 from "../img/carosello9.png"
import img10 from "../img/carosello10.png"
import img11 from "../img/carosello11.png"

const slides = [
  { id: 0, url: img1},
  { id: 1, url: img2},
  { id: 2, url: img3},
  { id: 3, url: img4},
  { id: 4, url: img5},
  { id: 5, url: img6},
  { id: 6, url: img7},
  { id: 7, url: img8},
  { id: 8, url: img9},
  { id: 9, url: img10},
  { id: 10, url:img11 }
]

const Popplio = () => {
  const [index, set] = useState(0)
  const transitions = useTransition(slides[index], item => item.id, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: config.molasses,
  })
  useEffect(() => void setInterval(() => set(state => (state + 1)%10), 2000), [])
  return transitions.map(({ item, props, key }) => (
    <animated.div
      key={key}
      class="bg"
      style={{ ...props, backgroundImage: `url(${item.url})`,width:"100%",height:"100%",float:"left", backgroundSize:"contain", backgroundRepeat:"no-repeat" }}
    />
  ))
}
export default Popplio;