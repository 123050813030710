import React, { useState, render } from 'react'
import '../../App.css'
import Carousel from 'react-bootstrap/Carousel'
import Carosello1 from "../../img/Y1.jpg"
import Carosello2 from "../../img/Y2.jpg"
import Carosello3 from "../../img/Y3.jpg"
import Carosello4 from "../../img/Y4.jpg"
import Carosello5 from "../../img/Y5.jpg"
import Carosello6 from "../../img/Y6.jpg"
import Carosello7 from "../../img/Y7.jpg"
import Carosello8 from "../../img/Y8.jpg"
import Carosello9 from "../../img/Y9.jpg"
import Carosello10 from "../../img/Y10.jpg"
import Carosello11 from "../../img/Y11.jpg"
import Carosello12 from "../../img/Y12.jpg"
import Carosello13 from "../../img/Y13.jpg"
import Carosello14 from "../../img/Y14.jpg"
import Carosello15 from "../../img/Y15.jpg"
import Carosello16 from "../../img/Y16.jpg"
import Carosello17 from "../../img/Y17.jpg"
import Carosello18 from "../../img/Y18.jpg"
import Carosello19 from "../../img/Y19.jpg"
import Carosello20 from "../../img/Y20.jpg"



function ControlledCarousel2() {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  return (
    <Carousel activeIndex={index} onSelect={handleSelect}>
      <Carousel.Item style={{backgroundImage: `url(${Carosello1})`,backgroundSize:"cover",backgroundRepeat:"no-repeat",height:"450px",float:"left",marginTop:"0%",borderRadius:"10px 10px 0px 0px"}}>
      </Carousel.Item>
      <Carousel.Item style={{backgroundImage: `url(${Carosello2})`,backgroundSize:"cover",backgroundRepeat:"no-repeat",height:"450px",float:"left",marginTop:"0%",borderRadius:"10px 10px 0px 0px"}}>
      </Carousel.Item>
      <Carousel.Item style={{backgroundImage: `url(${Carosello3})`,backgroundSize:"cover",backgroundRepeat:"no-repeat",height:"450px",float:"left",marginTop:"0%",borderRadius:"10px 10px 0px 0px"}}>
      </Carousel.Item>
      <Carousel.Item style={{backgroundImage: `url(${Carosello4})`,backgroundSize:"cover",backgroundRepeat:"no-repeat",height:"450px",float:"left",marginTop:"0%",borderRadius:"10px 10px 0px 0px"}}>
      </Carousel.Item>
      <Carousel.Item style={{backgroundImage: `url(${Carosello5})`,backgroundSize:"cover",backgroundRepeat:"no-repeat",height:"450px",float:"left",marginTop:"0%",borderRadius:"10px 10px 0px 0px"}}>
      </Carousel.Item>
      <Carousel.Item style={{backgroundImage: `url(${Carosello6})`,backgroundSize:"cover",backgroundRepeat:"no-repeat",height:"450px",float:"left",marginTop:"0%",borderRadius:"10px 10px 0px 0px"}}>
      </Carousel.Item>
      <Carousel.Item style={{backgroundImage: `url(${Carosello7})`,backgroundSize:"cover",backgroundRepeat:"no-repeat",height:"450px",float:"left",marginTop:"0%",borderRadius:"10px 10px 0px 0px"}}>
      </Carousel.Item>
      <Carousel.Item style={{backgroundImage: `url(${Carosello8})`,backgroundSize:"cover",backgroundRepeat:"no-repeat",height:"450px",float:"left",marginTop:"0%",borderRadius:"10px 10px 0px 0px"}}>
      </Carousel.Item>
      <Carousel.Item style={{backgroundImage: `url(${Carosello9})`,backgroundSize:"cover",backgroundRepeat:"no-repeat",height:"450px",float:"left",marginTop:"0%",borderRadius:"10px 10px 0px 0px"}}>
      </Carousel.Item>
      <Carousel.Item style={{backgroundImage: `url(${Carosello10})`,backgroundSize:"cover",backgroundRepeat:"no-repeat",height:"450px",float:"left",marginTop:"0%",borderRadius:"10px 10px 0px 0px"}}>
      </Carousel.Item>
      <Carousel.Item style={{backgroundImage: `url(${Carosello11})`,backgroundSize:"cover",backgroundRepeat:"no-repeat",height:"450px",float:"left",marginTop:"0%",borderRadius:"10px 10px 0px 0px"}}>
      </Carousel.Item>
      <Carousel.Item style={{backgroundImage: `url(${Carosello12})`,backgroundSize:"cover",backgroundRepeat:"no-repeat",height:"450px",float:"left",marginTop:"0%",borderRadius:"10px 10px 0px 0px"}}>
      </Carousel.Item>
      <Carousel.Item style={{backgroundImage: `url(${Carosello13})`,backgroundSize:"cover",backgroundRepeat:"no-repeat",height:"450px",float:"left",marginTop:"0%",borderRadius:"10px 10px 0px 0px"}}>
      </Carousel.Item>
      <Carousel.Item style={{backgroundImage: `url(${Carosello14})`,backgroundSize:"cover",backgroundRepeat:"no-repeat",height:"450px",float:"left",marginTop:"0%",borderRadius:"10px 10px 0px 0px"}}>
      </Carousel.Item>
      <Carousel.Item style={{backgroundImage: `url(${Carosello15})`,backgroundSize:"cover",backgroundRepeat:"no-repeat",height:"450px",float:"left",marginTop:"0%",borderRadius:"10px 10px 0px 0px"}}>
      </Carousel.Item>
      <Carousel.Item style={{backgroundImage: `url(${Carosello16})`,backgroundSize:"cover",backgroundRepeat:"no-repeat",height:"450px",float:"left",marginTop:"0%",borderRadius:"10px 10px 0px 0px"}}>
      </Carousel.Item>
      <Carousel.Item style={{backgroundImage: `url(${Carosello17})`,backgroundSize:"cover",backgroundRepeat:"no-repeat",height:"450px",float:"left",marginTop:"0%",borderRadius:"10px 10px 0px 0px"}}>
      </Carousel.Item>
      <Carousel.Item style={{backgroundImage: `url(${Carosello18})`,backgroundSize:"cover",backgroundRepeat:"no-repeat",height:"450px",float:"left",marginTop:"0%",borderRadius:"10px 10px 0px 0px"}}>
      </Carousel.Item>
      <Carousel.Item style={{backgroundImage: `url(${Carosello19})`,backgroundSize:"cover",backgroundRepeat:"no-repeat",height:"450px",float:"left",marginTop:"0%",borderRadius:"10px 10px 0px 0px"}}>
      </Carousel.Item>
      <Carousel.Item style={{backgroundImage: `url(${Carosello20})`,backgroundSize:"cover",backgroundRepeat:"no-repeat",height:"450px",float:"left",marginTop:"0%",borderRadius:"10px 10px 0px 0px"}}>
      </Carousel.Item>
    </Carousel>
  );
}

export default ControlledCarousel2;