import React, { useEffect, useState, useRef } from 'react'
import './App.css'

function TextAnimY() {
    const [vwidth, setvwidth] = useState(0)
    const [vheight, setvheight] = useState(0)
  
    const updateDimensions = () => {
      let update_width = window.innerWidth;
      let update_height = window.innerHeight;
      setvwidth(update_width)
      setvheight(update_height)
    }

    return (
        <div  >
            <svg width="60%" height="107" viewBox="0 0 538 107" fill="none" xmlns="http://www.w3.org/2000/svg" className='TestoYYY'>
                <mask id="path-1-outside-1" maskUnits="userSpaceOnUse" x="-0.945312" y="0.625" width="539" height="106" fill="none">
                    <rect fill="white" x="-0.945312" y="0.625" width="539" height="106" />
                    <path d="M43.1016 53.0234L69.8203 1.625H85.1484L49.8516 65.8203V104H36.3516V65.8203L1.05469 1.625H16.5234L43.1016 53.0234Z" className='Y1' />
                    <path d="M88.2422 65.2578C88.2422 57.8047 89.6953 51.1016 92.6016 45.1484C95.5547 39.1953 99.6328 34.6016 104.836 31.3672C110.086 28.1328 116.062 26.5156 122.766 26.5156C133.125 26.5156 141.492 30.1016 147.867 37.2734C154.289 44.4453 157.5 53.9844 157.5 65.8906V66.8047C157.5 74.2109 156.07 80.8672 153.211 86.7734C150.398 92.6328 146.344 97.2031 141.047 100.484C135.797 103.766 129.75 105.406 122.906 105.406C112.594 105.406 104.227 101.82 97.8047 94.6484C91.4297 87.4766 88.2422 77.9844 88.2422 66.1719V65.2578ZM101.32 66.8047C101.32 75.2422 103.266 82.0156 107.156 87.125C111.094 92.2344 116.344 94.7891 122.906 94.7891C129.516 94.7891 134.766 92.2109 138.656 87.0547C142.547 81.8516 144.492 74.5859 144.492 65.2578C144.492 56.9141 142.5 50.1641 138.516 45.0078C134.578 39.8047 129.328 37.2031 122.766 37.2031C116.344 37.2031 111.164 39.7578 107.227 44.8672C103.289 49.9766 101.32 57.2891 101.32 66.8047Z" className='Y2' />
                    <path d="M220.781 96.4766C215.719 102.43 208.289 105.406 198.492 105.406C190.383 105.406 184.195 103.062 179.93 98.375C175.711 93.6406 173.578 86.6562 173.531 77.4219V27.9219H186.539V77.0703C186.539 88.6016 191.227 94.3672 200.602 94.3672C210.539 94.3672 217.148 90.6641 220.43 83.2578V27.9219H233.438V104H221.062L220.781 96.4766Z" className='Y3' />
                    <path d="M255.305 104V1.625H284.203C293.109 1.625 300.984 3.59375 307.828 7.53125C314.672 11.4688 319.945 17.0703 323.648 24.3359C327.398 31.6016 329.297 39.9453 329.344 49.3672V55.9062C329.344 65.5625 327.469 74.0234 323.719 81.2891C320.016 88.5547 314.695 94.1328 307.758 98.0234C300.867 101.914 292.828 103.906 283.641 104H255.305ZM268.805 12.7344V92.9609H283.008C293.414 92.9609 301.5 89.7266 307.266 83.2578C313.078 76.7891 315.984 67.5781 315.984 55.625V49.6484C315.984 38.0234 313.242 29 307.758 22.5781C302.32 16.1094 294.586 12.8281 284.555 12.7344H268.805Z" className='Y4' />
                    <path d="M344.32 65.2578C344.32 57.8047 345.773 51.1016 348.68 45.1484C351.633 39.1953 355.711 34.6016 360.914 31.3672C366.164 28.1328 372.141 26.5156 378.844 26.5156C389.203 26.5156 397.57 30.1016 403.945 37.2734C410.367 44.4453 413.578 53.9844 413.578 65.8906V66.8047C413.578 74.2109 412.148 80.8672 409.289 86.7734C406.477 92.6328 402.422 97.2031 397.125 100.484C391.875 103.766 385.828 105.406 378.984 105.406C368.672 105.406 360.305 101.82 353.883 94.6484C347.508 87.4766 344.32 77.9844 344.32 66.1719V65.2578ZM357.398 66.8047C357.398 75.2422 359.344 82.0156 363.234 87.125C367.172 92.2344 372.422 94.7891 378.984 94.7891C385.594 94.7891 390.844 92.2109 394.734 87.0547C398.625 81.8516 400.57 74.5859 400.57 65.2578C400.57 56.9141 398.578 50.1641 394.594 45.0078C390.656 39.8047 385.406 37.2031 378.844 37.2031C372.422 37.2031 367.242 39.7578 363.305 44.8672C359.367 49.9766 357.398 57.2891 357.398 66.8047Z" className='Y5' />
                    <path d="M442.125 27.9219L442.477 36.3594C448.055 29.7969 455.578 26.5156 465.047 26.5156C475.688 26.5156 482.93 30.5938 486.773 38.75C489.305 35.0938 492.586 32.1406 496.617 29.8906C500.695 27.6406 505.5 26.5156 511.031 26.5156C527.719 26.5156 536.203 35.3516 536.484 53.0234V104H523.477V53.7969C523.477 48.3594 522.234 44.3047 519.75 41.6328C517.266 38.9141 513.094 37.5547 507.234 37.5547C502.406 37.5547 498.398 39.0078 495.211 41.9141C492.023 44.7734 490.172 48.6406 489.656 53.5156V104H476.578V54.1484C476.578 43.0859 471.164 37.5547 460.336 37.5547C451.805 37.5547 445.969 41.1875 442.828 48.4531V104H429.82V27.9219H442.125Z" className='Y6' />
                </mask>
                <path d="M43.1016 53.0234L69.8203 1.625H85.1484L49.8516 65.8203V104H36.3516V65.8203L1.05469 1.625H16.5234L43.1016 53.0234Z" stroke="white" stroke-width="2" mask="url(#path-1-outside-1)" className='Y1' />
                <path d="M88.2422 65.2578C88.2422 57.8047 89.6953 51.1016 92.6016 45.1484C95.5547 39.1953 99.6328 34.6016 104.836 31.3672C110.086 28.1328 116.062 26.5156 122.766 26.5156C133.125 26.5156 141.492 30.1016 147.867 37.2734C154.289 44.4453 157.5 53.9844 157.5 65.8906V66.8047C157.5 74.2109 156.07 80.8672 153.211 86.7734C150.398 92.6328 146.344 97.2031 141.047 100.484C135.797 103.766 129.75 105.406 122.906 105.406C112.594 105.406 104.227 101.82 97.8047 94.6484C91.4297 87.4766 88.2422 77.9844 88.2422 66.1719V65.2578ZM101.32 66.8047C101.32 75.2422 103.266 82.0156 107.156 87.125C111.094 92.2344 116.344 94.7891 122.906 94.7891C129.516 94.7891 134.766 92.2109 138.656 87.0547C142.547 81.8516 144.492 74.5859 144.492 65.2578C144.492 56.9141 142.5 50.1641 138.516 45.0078C134.578 39.8047 129.328 37.2031 122.766 37.2031C116.344 37.2031 111.164 39.7578 107.227 44.8672C103.289 49.9766 101.32 57.2891 101.32 66.8047Z" stroke="white" stroke-width="2" mask="url(#path-1-outside-1)" className='Y2' />
                <path d="M220.781 96.4766C215.719 102.43 208.289 105.406 198.492 105.406C190.383 105.406 184.195 103.062 179.93 98.375C175.711 93.6406 173.578 86.6562 173.531 77.4219V27.9219H186.539V77.0703C186.539 88.6016 191.227 94.3672 200.602 94.3672C210.539 94.3672 217.148 90.6641 220.43 83.2578V27.9219H233.438V104H221.062L220.781 96.4766Z" stroke="white" stroke-width="2" mask="url(#path-1-outside-1)" className='Y3' />
                <path d="M255.305 104V1.625H284.203C293.109 1.625 300.984 3.59375 307.828 7.53125C314.672 11.4688 319.945 17.0703 323.648 24.3359C327.398 31.6016 329.297 39.9453 329.344 49.3672V55.9062C329.344 65.5625 327.469 74.0234 323.719 81.2891C320.016 88.5547 314.695 94.1328 307.758 98.0234C300.867 101.914 292.828 103.906 283.641 104H255.305ZM268.805 12.7344V92.9609H283.008C293.414 92.9609 301.5 89.7266 307.266 83.2578C313.078 76.7891 315.984 67.5781 315.984 55.625V49.6484C315.984 38.0234 313.242 29 307.758 22.5781C302.32 16.1094 294.586 12.8281 284.555 12.7344H268.805Z" stroke="white" stroke-width="2" mask="url(#path-1-outside-1)" className='Y4' />
                <path d="M344.32 65.2578C344.32 57.8047 345.773 51.1016 348.68 45.1484C351.633 39.1953 355.711 34.6016 360.914 31.3672C366.164 28.1328 372.141 26.5156 378.844 26.5156C389.203 26.5156 397.57 30.1016 403.945 37.2734C410.367 44.4453 413.578 53.9844 413.578 65.8906V66.8047C413.578 74.2109 412.148 80.8672 409.289 86.7734C406.477 92.6328 402.422 97.2031 397.125 100.484C391.875 103.766 385.828 105.406 378.984 105.406C368.672 105.406 360.305 101.82 353.883 94.6484C347.508 87.4766 344.32 77.9844 344.32 66.1719V65.2578ZM357.398 66.8047C357.398 75.2422 359.344 82.0156 363.234 87.125C367.172 92.2344 372.422 94.7891 378.984 94.7891C385.594 94.7891 390.844 92.2109 394.734 87.0547C398.625 81.8516 400.57 74.5859 400.57 65.2578C400.57 56.9141 398.578 50.1641 394.594 45.0078C390.656 39.8047 385.406 37.2031 378.844 37.2031C372.422 37.2031 367.242 39.7578 363.305 44.8672C359.367 49.9766 357.398 57.2891 357.398 66.8047Z" stroke="white" stroke-width="2" mask="url(#path-1-outside-1)" className='Y5' />
                <path d="M442.125 27.9219L442.477 36.3594C448.055 29.7969 455.578 26.5156 465.047 26.5156C475.688 26.5156 482.93 30.5938 486.773 38.75C489.305 35.0938 492.586 32.1406 496.617 29.8906C500.695 27.6406 505.5 26.5156 511.031 26.5156C527.719 26.5156 536.203 35.3516 536.484 53.0234V104H523.477V53.7969C523.477 48.3594 522.234 44.3047 519.75 41.6328C517.266 38.9141 513.094 37.5547 507.234 37.5547C502.406 37.5547 498.398 39.0078 495.211 41.9141C492.023 44.7734 490.172 48.6406 489.656 53.5156V104H476.578V54.1484C476.578 43.0859 471.164 37.5547 460.336 37.5547C451.805 37.5547 445.969 41.1875 442.828 48.4531V104H429.82V27.9219H442.125Z" stroke="white" stroke-width="2" mask="url(#path-1-outside-1)" className='Y6' />
            </svg>
        </div>
    )
}
export default TextAnimY;