import React, { useEffect, useState } from "react";
import { csv } from "d3-fetch";
import { scaleLinear } from "d3-scale";
import {
  ComposableMap,
  Geographies,
  Geography,
  Sphere,
  Graticule
} from "react-simple-maps";
import foto from './Marker.png';
import map2 from './map2.png'

const geoUrl =
  "https://raw.githubusercontent.com/zcreativelabs/react-simple-maps/master/topojson-maps/world-110m.json";

const colorScale = scaleLinear()
  .domain([0.29, 0.68])
  .range(["#ffedea", "#ff5233"]);

const MapChart = (props) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    csv(`/vulnerability.csv`).then((data) => {
      setData(data);
    });
  }, []);
  const [vwidth, setvwidth] = useState(0)
  const [vheight, setvheight] = useState(0)

  const updateDimensions = () => {
    let update_width = window.innerWidth;
    let update_height = window.innerHeight;
    setvwidth(update_width)
    setvheight(update_height)
  }

  const [counter, setCounter] = useState(0);
  const [offsetY, setOffsetY,] = useState(0)

  /*["LEFT%", "TOP%","index"] */
  let markerList = [["25%", "-18%", ""], ["15%", "-38.7128%", (vwidth>100?"2":"")], ["9%", "-39%", ""], ["19%", "-39.7128%", (vwidth>100?"2":"")], ["15.5%", "-37.7128%", ""], ["43.3%", "-37.53%", (vwidth>100?"3":"")], ["41.9%", "-40%", (vwidth>100?"37":"")], ["48%", "-41.5%", ""], ["56.3%", "-37%", ""], ["39.9%", "-40.5%",(vwidth>100?"10":"")], ["44.3%", "-41%", (vwidth>100?"3":"")], ["49.5%", "-43.5%", (vwidth>100?"2":"")], ["62%", "-34%", (vwidth>100?"4":"")], ["71.5%", "-22.5%", ""],["82%", "-15%", ""],["9%", "-31%", ""]];


  const creaMarker = () => {
    let temp = [];
    let delay = 0;
    markerList.map((element, x) => {

      temp.push(
        <div data-aos="fade-down"
          data-aos-offset="-10000"
          data-aos-delay={delay}
          data-aos-duration="0.5"
          data-aos-easing="ease-in-out"
        >
          <div style={{ float: 'left', marginLeft: element[0], marginTop: element[1], height: "3.5%", width: '3.5%' }}>
            <img src={foto} alt="foto" height="100%" width='100%'>
            </img>
            <div style={{ fontSize: '12px', marginLeft: element[2] > 9 ? "40%" : "45%", position: 'relative', marginTop: "-82%", height: "100%", width: '100%' }}>
              <text><b >{element[2]}</b></text>
            </div>
          </div>
        </div>
      )
      delay = delay + 200
      if (delay >= 1000) {
        delay = 0;
      }
    }
    )
    return temp.map((temp) => temp)
  }
  return (
    <div style={{ height: "100%", width: '100%' }}>
      <div >
        <img
          src={map2} alt="map2" height="100%" width='100%' >
        </img>
      </div>
      <div>
        {creaMarker()}
      </div>

    </div>
  );
};

export default MapChart;
