import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useEffect, useState, useRef } from 'react';
import './App.css'; import 'aos/dist/aos.css';
import { Link } from 'react-router-dom'
import * as ReactBootStrap from 'react-bootstrap';
import 'react-slideshow-image/dist/styles.css'
import ReactPlayer from "react-player"
import TextAnimY from './TextAnimY'
import ControlledCarousel2 from './components/Carosello/ControlledCarousel2'
import backgroundImagine from "./img/bground1.png"
import Registered from "./img/registered1.png"
import backgroundTelefono from "./img/phone.png"
import backgroundTelefono2 from "./img/phone.png"
import carosello2 from './img/carosello2.png'
import logo from './img/sadsdsadda.png'
import Youdompng from "./img/carosello2.png"
import ImmagineBackground4 from "./img/sfondoEmail.jpg"
import Carousel from 'react-bootstrap/Carousel'
import Carosello1 from "./img/Cattura1.jpg"
import Carosello2 from "./img/Cattura2.jpg"
import Carosello3 from "./img/Cattura3.jpg"
import Carosello4 from "./img/Cattura4.jpg"
import Carosello5 from "./img/Cattura5.jpg"
import Carosello6 from "./img/Cattura6.jpg"
import Carosello7 from "./img/Cattura7.jpg"
import Carosello8 from "./img/Cattura8.jpg"
import Carosello9 from "./img/Cattura9.jpg"
import Carosello10 from "./img/Cattura10.jpg"
import Carosello11 from "./img/Cattura11.jpg"
import Carosello12 from "./img/Cattura12.jpg"
import Carosello13 from "./img/Cattura13.jpg"
import Carosello14 from "./img/Cattura14.jpg"
import Carosello15 from "./img/Cattura15.jpg"
import ImmagineBackground5 from "./img/verticale-logo.png"
import ImmaginePor from "./img/logoPor.png"
import { isMobile } from 'react-device-detect';




function YouDom() {

  useEffect(() => {
    console.log(vwidth)
    window.scrollTo(0, 0);
    updateDimensions();
    window.addEventListener("resize", updateDimensions.bind(this));
    window.addEventListener("scroll", handleScroll)

    const interval = setInterval(() => {
      var temp = eCarousel1Dimensions.current.offsetHeight * 0.9
      setH(temp)
      temp = floatLabelDimensions.current.offsetHeight * 0.9
      setfloatH(temp)
      temp = floatLabelDimensions.current.offsetWidth * 0.9
      setfloatW(temp)

    }, 1000);

    // Specify how to clean up after this effect:
    return () => {
      window.removeEventListener("resize", updateDimensions.bind(this))
      window.removeEventListener('scroll', handleScroll)
      clearInterval(interval);
    }
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const [vwidth, setvwidth] = useState(0)
  const [vheight, setvheight] = useState(0)
  const [eCarousel1Dimensions, seteCarousel1Dimensions] = useState(React.createRef())
  const [H, setH] = useState(10)
  const Hh = H + "px";
  const [offsetY, setOffsetY,] = useState(0);
  const handleScroll = () => setOffsetY(window.pageYOffset)
  const commentSection1Y = useRef(null);
  const commentSection2Y = useRef(null);
  const [floatLabelDimensions, setfloatLabelDimensions] = useState(React.createRef())
  const [floatH, setfloatH] = useState(10)
  const [floatW, setfloatW] = useState(10)

  const handleOnClick = (
  ) => window.location.replace("https://www.verticale.net/myazienda_scheda.php?dominio=sapitec.net&ragsoc=Sapitec");

  const updateDimensions = (e) => {
    let update_width = window.innerWidth;
    let update_height = window.innerHeight;
    setvwidth(update_width)
    setvheight(update_height)
  }
  const gotoCommentSection1Y = () => window.scrollTo({
    top: commentSection1Y.current.offsetTop,
    behavior: "smooth"
  });
  const gotoCommentSection2Y = () => window.scrollTo({
    top: commentSection2Y.current.offsetTop,
    behavior: "smooth"
  });
  const gotoCommentSection3Y = () => window.scrollTo({
    top: "0px",
    behavior: "smooth"
  });

  return (
    <div>
      <body style={{ height: "3600px", width: "100%", position: "relative", marginTop: "0px", backgroundColor: "black" }} >
        {/*-------------------NAVBAR--------------- */}
        <nav>
          <div style={{ height: "5%" }}>
            <ReactBootStrap.Navbar collapseOnSelect expand="lg"  variant="dark" fixed="top"
             style={{ backgroundColor: "#000535", 
             width: "100%", position: "fixed" }} >
              <Link to='/' style={{ backgroundImage: `url(${logo})`,
               backgroundSize: "contain", backgroundRepeat: "no-repeat",
                height: "20px" }}><image Link className="logo" ></image></Link>
              <ReactBootStrap.Navbar.Toggle aria-controls="responsive-navbar-nav" />
              <ReactBootStrap.Navbar.Collapse id="responsive-navbar-nav">
                <ReactBootStrap.Nav className="mr-auto">
                  <Link className='HomeT' onClick={gotoCommentSection3Y}>YouDom< div style={{ marginTop: "4px", marginLeft: "2px", float: "right", backgroundImage: `url(${Youdompng})`, backgroundSize: "contain", backgroundRepeat: "no-repeat", width: "20px", height: "20px" }} /></Link>
                  <Link className='HomeT' onClick={gotoCommentSection1Y}>Video</Link>
                  <Link className='HomeT' onClick={gotoCommentSection2Y}>Contatti</Link>
                  <Link className='HomeT' ><a onClick={handleOnClick}>
                    < div style={{
                      marginTop: "-5px", marginLeft: "2px", float: "right",
                      backgroundImage: `url(${ImmagineBackground5})`, backgroundSize: "contain", backgroundRepeat: "no-repeat", width: "65px", height: "30px"
                    }} /></a>
                  </Link>
                  <Link className='HomeT' to='/Por'>
                    < div style={{
                      marginTop: "4px", marginLeft: "2px", float: "right",
                      backgroundImage: `url(${ImmaginePor})`, backgroundSize: "cover", backgroundRepeat: "no-repeat", width: "90px", height: "20px"
                    }} />
                  </Link>
                  <ReactBootStrap.NavDropdown.Divider />
                </ReactBootStrap.Nav>
              </ReactBootStrap.Navbar.Collapse>
            </ReactBootStrap.Navbar>
          </div>
        </nav>
        {/*-----------------SEZIONE 1--------------- */}
        <div style={{
          position: "relative", width: "100%", height: isMobile ? "1080px" : "1440px", backgroundImage: `url(${backgroundImagine})`,
          backgroundRepeat: "no-repeat", backgroundSize: "cover"
        }}>
          {/*-----------------TITOLO E SCRITTA--------------- */}
          <div style={{ width: "100%", height: "10%", top: isMobile ? "7%" : "5%", left: "0%", position: "absolute" }} >
            <div style={{ position: "relative", float: "right", width: "40px", height: "40px", backgroundImage: `url(${Registered})`, backgroundSize: "contain", backgroundRepeat: "no-repeat", marginTop: "0%", marginLeft: "65%", position: "absolute" }} className="R"></div>
            <TextAnimY />
            <div style={{ color: "white", fontSize: "3vw", fontFamily: "unset", marginTop: isMobile ? "4%" : "7%", float: "left", position: "absolute", left: "0%", width: "100%", textAlign: "center" }}
              data-aos="fade-up"
              data-aos-offset="0"
              data-aos-delay="100"
              data-aos-duration="5000"
              data-aos-easing="ease-in-out">
              Trasforma la tua casa in una casa intelligente
           </div>
          </div>
          {/*-----------------IMMAGINE--------------- */}
          <div style={{
            width: "15%", height: "220px", backgroundSize: "contain", backgroundRepeat: "no-repeat", float: "left", backgroundImage: `url(${carosello2})`,
            marginTop: (isMobile ? "18%" : "3%"), marginLeft: "4%", backgroundColor: "transparent", position: "absolute"
          }} data-aos="zoom-in"
            data-aos-offset="0"
            data-aos-delay="100"
            data-aos-duration="5000"
            data-aos-easing="ease-in-out" >
          </div>
          <h1 style={{ backgroundColor: "black", height: "5px" }}></h1>
          {/*-----------------TABELLA 1--------------- */}
          <div
            ref={floatLabelDimensions}
            style={{
              width: isMobile ? "40%" : "20%",
              height: isMobile ? "400px" : "432px",
              backgroundColor: "rgb(0, 0, 0,60%)",
              color: "white",
              float: "left",
              marginLeft: "5%",
              marginTop: isMobile ? "45%" : "25%",
              borderRadius: "10px 10px 10px 10px",
              animation: "float 6s ease-in-out infinite",
              position: "absolute",
              fontWeight: "bold",
              wordWrap: "break-word"
            }}
          >
            <div className='titleCY'>
              Cos’è Youdom?
                </div>
            <div style={{
              position: "absolute", width: "95%", height: "100%",
              marginLeft: "2%", textAlign: "center", fontSize: isMobile ? "11px" : "15px",
            }}>
              Youdom è un sistema domotico avanzato, pensato per essere
              estremamente configurabile e personalizzabile.
              E' realizzato utilizzando tecnologie derivate dall'automazione
              industriale che garantiscono  pertanto affidabilità, sicurezza,
              reperibilita' e un lungo ciclo di di vita del prodotto e relativi ricambi.
              YouDom e' multilivello in quanto l' applicazione integra domotica,
              clima, sicurezza, videosorveglianza ma allo stesso tempo lascia a
              ciascun ambito le proprie competenze.
             {/* Con YouDom avrai il pieno controllo della tua casa,
              dalla semplice accensione delle luci al più complicato degli eventi.*/}
            </div>
          </div>
          {/*-----------------TABELLA 2--------------- */}
          <div
            style={{
              position: "absolute",
              width: isMobile ? "40%" : "20%",
              height: isMobile ? "400px" : "432px",
              backgroundColor: "rgb(0, 0, 0,60%)",
              color: "white",
              float: "left",
              marginLeft: isMobile ? "55%" : "28%",
              marginTop: (isMobile ? "60%" : "30%"),
              animation: "float 6s ease-in-out infinite",
              borderRadius: "10px 10px 10px 10px",
              fontWeight: "bold",
              wordWrap: "break-word"
            }}>
            <div className='titleCY'>
              Flessibilità
                  </div>
            <div style={{
              position: "absolute", width: "95%", height: "100%",
              marginLeft: "2%", textAlign: "center", fontSize: isMobile ? "11px" : "15px", /*textJustify: "auto"
              , textAlign: "justify"*/
            }}>
              Youdom si distingue per l’enorme flessibilità: è progettata per essere costruita secondo le TUE esigenze.
              I nostri tecnici ascolteranno le tue richieste e personalizzeranno l’applicazione in modo che venga incontro alle tue necessità.
              Il sistema è anche pensato per essere personalizzabile facilmente e senza l’aiuto di un tecnico, in questo modo sarà sempre flessibile a nuove necessità.
                  </div>
          </div>
          {/*-----------------TABELLA 3--------------- */}
          <div
            style={{
              position: "absolute",
              width: isMobile ? "40%" : "20%",
              height: isMobile ? "400px" : "432px",
              backgroundColor: "rgb(0, 0, 0,60%)",
              color: "white",
              float: "left",
              marginLeft: isMobile ? "5%" : "52%",
              marginTop: (isMobile ? "600px" : "30%"),
              boxShadow: "0px 12px 18px -6px rgba(0,0,0,0,.3)",
              borderRadius: " 10px 10px 10px 10px",
              animation: "float 6s ease-in-out infinite",
              fontWeight: "bold",
              wordWrap: "break-word"
            }}>
            <div className='titleCY'>
              Eco
                  </div>
            <div style={{
              position: "absolute", width: "95%", height: "100%", marginLeft: "2%",
              textAlign: "center", fontSize: isMobile ? "11px" : "15px", /*textJustify: "auto"
              , textAlign: "justify"*/
            }}>
              Youdom è pensata per una casa GREEN: grazie al costante controllo dei livelli energetici e alla possibilità di automatizzare l’abitazione in base ad essi,
              Youdom ti aiuterà a tenere sotto controllo i tuoi consumi per una casa più sostenibile ed una bolletta più leggere.
                  </div>
          </div>
          {/*-----------------TABELLA 4--------------- */}
          <div
            style={{
              position: "absolute",
              width: isMobile ? "40%" : "20%",
              height: isMobile ? "400px" : "432px",
              backgroundColor: "rgb(0, 0, 0,60%)",
              color: "white",
              float: "left",
              marginLeft: isMobile ? "55%" : "75%",
              marginTop: (isMobile ? "660px" : "25%"),
              boxShadow: "0px 12px 18px -6px rgba(0,0,0,0,.3)",
              borderRadius: "10px 10px 10px 10px",
              animation: "float 6s ease-in-out infinite",
              fontWeight: "bold",
              wordWrap: "break-word"
            }}>
            <div className='titleCY'>
              Assistenza
                  </div>
            <div style={{
              position: "absolute", width: "95%", height: "100%",
              marginLeft: "2%", textAlign: "center", fontSize: isMobile ? "11px" : "15px",/* textJustify: "auto"
              , textAlign: "justify"*/
            }}>
              Youdom è un prodotto 100% made in italy, quindi i nostri tecnici saranno sempre disponibili per le vostre domande e il supporto tecnico.
                    </div>
          </div>
        </div>
        {/*-----------------SEZIONE 2--------------- */}
        <div style={{
          position: "relative", width: "100%",
          height: isMobile ? "1440px" : "1440px", backgroundImage: `url(${backgroundImagine})`,
          backgroundRepeat: "no-repeat", backgroundSize: "cover"
        }} ref={commentSection1Y}>
          {/*-----------------TELEFONO-------------- */}
          <div style={{
            backgroundImage: `url(${backgroundTelefono})`, backgroundSize: "cover", backgroundRepeat: "no-repeat", width: "280px",
            height: "532px", float: "left", marginLeft: "5%", marginTop: "5%"
          }}>
            <div style={{
              backgroundImage: `url(${backgroundTelefono2})`, backgroundRepeat: "no-repeat", backgroundSize: "cover",
              width: "250px", height: "435px", float: "left", marginLeft: " 6.5%", marginTop: "10%",
              display: "inline-block"
            }}>
              <ControlledCarousel2 ></ControlledCarousel2>
            </div>
          </div>

          {/*----------------- TABLET -------------- */}
          <div
            ref={eCarousel1Dimensions}
            style={{
              width: (isMobile ? "90%" : vwidth * 3 / 5 + "px"),
              height: (isMobile ? "40%" : vheight / 1.4 + "px"),
              float: "left",
              marginLeft: "5%",
              marginTop: "3%",
              backgroundColor: "black",
              border: "5px solid grey",
              borderRadius: "25px 25px 25px 25px",
              display: "inline-block"
            }}>

            <Carousel style={{
              marginTop: "2.5%", marginLeft: "6%", position: "relative",
              width: "88%", height: Hh, textAlign: "center"
            }}>
              <Carousel.Item interval={1000} >
                <img
                  className="d-block w-auto"
                  src={Carosello1}
                  alt="First slide"
                  width="auto"
                  height={Hh}
                  style={{ borderRadius: "10px 10px 10px 10px" }}
                />
              </Carousel.Item>
              <Carousel.Item interval={500}>
                <img
                  className="d-block w-auto"
                  src={Carosello2}
                  alt="Third slide"
                  width="auto"
                  borderRadius="10px 10px 10px 10px"
                  height={Hh}
                  style={{ borderRadius: "10px 10px 10px 10px" }}
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="d-block w-auto"
                  src={Carosello3}
                  alt="Third slide"
                  width="auto"
                  borderRadius="10px 10px 10px 10px" x
                  height={Hh}
                  style={{ borderRadius: "10px 10px 10px 10px" }}

                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="d-block w-auto"
                  src={Carosello4}
                  alt="Third slide"
                  width="auto"
                  borderRadius="10px 10px 10px 10px" x
                  height={Hh}
                  style={{ borderRadius: "10px 10px 10px 10px" }}

                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="d-block w-auto"
                  src={Carosello5}
                  alt="Third slide"
                  width="auto"
                  borderRadius="10px 10px 10px 10px" x
                  height={Hh}
                  style={{ borderRadius: "10px 10px 10px 10px" }}
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="d-block w-auto"
                  src={Carosello6}
                  alt="Third slide"
                  width="auto"
                  borderRadius="10px 10px 10px 10px" x
                  height={Hh}
                  style={{ borderRadius: "10px 10px 10px 10px" }}
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="d-block w-auto"
                  src={Carosello7}
                  alt="Third slide"
                  width="auto"
                  borderRadius="10px 10px 10px 10px" x
                  height={Hh}
                  style={{ borderRadius: "10px 10px 10px 10px" }}
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="d-block w-auto"
                  src={Carosello8}
                  alt="Third slide"
                  width="auto"
                  borderRadius="10px 10px 10px 10px" x
                  height={Hh}
                  style={{
                    borderRadius: "10px 10px 10px 10px",
                  }}
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="d-block w-auto"
                  src={Carosello9}
                  alt="Third slide"
                  width="auto"
                  borderRadius="10px 10px 10px 10px" x
                  height={Hh}
                  style={{ borderRadius: "10px 10px 10px 10px" }}
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="d-block w-auto"
                  src={Carosello10}
                  alt="Third slide"
                  width="auto"
                  borderRadius="10px 10px 10px 10px" x
                  height={Hh}
                  style={{ borderRadius: "10px 10px 10px 10px" }}
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="d-block w-auto"
                  src={Carosello11}
                  alt="Third slide"
                  width="auto"
                  borderRadius="10px 10px 10px 10px" x
                  height={Hh}
                  style={{ borderRadius: "10px 10px 10px 10px" }}
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="d-block w-auto"
                  src={Carosello12}
                  alt="Third slide"
                  width="auto"
                  borderRadius="10px 10px 10px 10px" x
                  height={Hh}
                  style={{ borderRadius: "10px 10px 10px 10px" }}
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="d-block w-auto"
                  src={Carosello13}
                  alt="Third slide"
                  width="auto"
                  borderRadius="10px 10px 10px 10px" x
                  height={Hh}
                  style={{ borderRadius: "10px 10px 10px 10px" }}
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="d-block w-auto"
                  src={Carosello14}
                  alt="Third slide"
                  width="auto"
                  borderRadius="10px 10px 10px 10px" x
                  height={Hh}
                  style={{ borderRadius: "10px 10px 10px 10px" }}
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="d-block w-auto"
                  src={Carosello15}
                  alt="Third slide"
                  width="auto"
                  borderRadius="10px 10px 10px 10px" x
                  height={Hh}
                  style={{ borderRadius: "10px 10px 10px 10px" }}
                />
              </Carousel.Item>
            </Carousel>
          </div>

          {/*-----------------VIDEO--------------- */}
          <ReactPlayer width={isMobile ? ("90%") : ("50%")}
            height={isMobile ? ("200px") : ("500px")} style={{
              marginTop: "3%",
              float: "left",
              marginLeft: isMobile ? ("5%") : ("25%"),
              marginTop: "3%"
            }}
            url="https://youtu.be/wgLF65Upybo"
          />

        </div>

        {/*-----------------FOOTER--------------- */}
        <div style={{
          width: "100%", height: (isMobile ? "30%" : "28%"), marginTop: "0%",
          color: " rgb(144, 148, 148)", display: "flex", position: "relative",
          flexDirection: (isMobile ? "column" : "row"), backgroundColor: "black",
          backgroundImage: `url(${ImmagineBackground4})`,
          backgroundRepeat: "no-repeat", backgroundSize: "cover", borderColor: "black",
          borderRadius: "0px 0px 0px 0px ", border: "1px solid  black"
        }} ref={commentSection2Y} >


          <div style={{
            marginLeft: "2%", marginTop: "5%", float: "left",
            width: (isMobile ? "96%" : "56%"), height: "700px", borderColor: "black",
            borderRadius: "0px 0px 0px 0px ", border: "1px solid  black"
          }}>
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2882.4783752836674!2d11.814709567344659!3d45.66909523864854!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4778d58c51f93495%3A0x9243dfd0fe53ac12!2sSapiTec%20Srl!5e1!3m2!1sit!2sit!4v1606141154500!5m2!1sit!2sit"
              style={{
                width: "100%", height: "100%", frameborder: "0", style: "border:0px",
                allowfullscreen: "", ariaHidden: "false", tabIndex: "0"
              }} ></iframe>
          </div>
          <div style={{
            marginLeft: "2%", marginTop: "5%", float: "left",
            borderColor: "black",
            borderRadius: "0px 0px 0px 0px ", border: "1px solid  black", color: "black",
            height: (isMobile ? "30%" : "700px"),
            width: (isMobile ? "96%" : "38%")
          }}>
            <div style={{ alignItems: "center", marginLeft: "1%" }}><h5>Info</h5>
              <a href="https://www.google.it/maps/place/Via+Enrico+Mattei,+35015+Galliera+Veneta+PD/@45.6695592,11.815257,187m/data=!3m1!1e3!4m5!3m4!1s0x4778d5d9044e4455:0x9c039314d83ebe5f!8m2!3d45.6697888!4d11.8156988">
                Posizione</a>
              <br />
              <a>Sede Legale: via Cervan 39 - 31033 - Castelfranco Veneto (TV) </a>
              <br />
              <a >Sede operativa: via L. da Vinci 47/A - 35015 - Galliera Veneta (PD)</a>
              <br />
              <br />
              <br />
              <br />
              <h5 >Email</h5>
              <a href="mailto: YouDom@sapitec.net">Contattaci</a>
              <br />
              <a >Info: YouDom@sapitec.net</a>
            </div>

          </div>
        </div>
        <div style={{ float: "left", marginBottom: "0%", backgroundColor: "black", position: "absolute", width: "100%", textAlign: "center" }}>
          <hr style={{ borderWidth: "0px", color: "white", width: "100%", height: "2px", backgroundColor: "white", marginTop: "0%" }}></hr>
          <a style={{ color: "white" }}>  <p className="copyright" style={{ marginTop: "0%", textAlign: "center" }}>
            &copy; {new Date().getFullYear()}{" "}
          Powered by {" "}
            <a href="http://sapitec.net">
              Sapitec  {" "}
            </a>| Telefono +39 0423 1950488  CF/PI 05084310266 - SDI. M5UXR1 - last update 10/04/2021
           </p>
          </a>
        </div>
      </body>
    </div>
  )
}
export default YouDom;