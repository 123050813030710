import { HashRouter as Router, Switch, Route, } from 'react-router-dom'
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useEffect, useState, useRef } from 'react';
import './App.css';
import TextAnim from './TextAnim'
import AOS from 'aos'
import 'aos/dist/aos.css';
import { Link } from 'react-router-dom'
import * as ReactBootStrap from 'react-bootstrap';
import ScrollToTop from './components/ScrollToTop';
import 'react-slideshow-image/dist/styles.css'
import ReactPlayer from "react-player"
import YouDom from './YouDom'
import Por from './Por'
import CountUp from 'react-countup';
import MapChart from "./components/MapChart";
import { useSpring, animated } from 'react-spring'
import Tilt from 'react-vanilla-tilt'
import Popplio from "./components/CaroselloStrano"
import ImmagineBackground from "./img/ImmagineBackground.jpg"
import ImmagineBackground4 from "./img/sfondoEmail.jpg"
import ImmagineBackground5 from "./img/verticale-logo.png"
import logo from './img/sadsdsadda.png'
import ImmaginePor from "./img/logoPor.png"
import Youdompng from "./img/carosello2.png"
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import BackgroundRovescio from "./img/backgroundRovescio.jpg"
import partnershipS from "./img/partnership.jpg"
import { useHistory } from 'react-router-dom';
import Carousel from 'react-bootstrap/Carousel'
import logoPartner1 from "./img/logosiemens.png"
import logoPartner2 from "./img/logoSchneider.png"
import logoPartner3 from "./img/asemlogo.png"
import logoPartner4 from "./img/b&rlogo.png"
import carosello1Img1 from "./img/Aut21.JPG"
import carosello1Img2 from "./img/Auto_1.JPG"
import carosello1Img3 from "./img/Auto_2.JPG"
import carosello1Img4 from "./img/Auto_3.JPG"
import carosello1Img5 from "./img/fanucZeta.JPG"
import carosello1Img6 from "./img/Yaskawa.JPG"
import carosello2Img1 from "./img/QE_1.png"
import carosello2Img2 from "./img/Qe_2.JPG"
import carosello2Img3 from "./img/Qe_3.JPG"
import carosello2Img4 from "./img/Qe_4.JPG"
import carosello2Img5 from "./img/Qe_11.JPG"
import carosello2Img6 from "./img/Qe_12.JPG"
import carosello2Img7 from "./img/Qe_13.JPG"
import carosello2Img8 from "./img/Qe_14.JPG"
import carosello2Img9 from "./img/vsc_vs.jpg"
import carosello2Img10 from "./img/TIA.jpg"
import { isMobile } from 'react-device-detect';


function App() {
  AOS.init({
    duration: 2000,
  })
  return (
    <Router>
      <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"></link>
      <div className="App">
        <Switch>
          <Route path='/' exact component={Media} />
          <Route path='/YouDom' component={YouDom} />
          <Route path='/Por' component={Por} />
        </Switch>
        <ScrollToTop />
      </div>
    </Router>
  );
}
const calc = (x, y) => [x - window.innerWidth / 3, y - window.innerHeight / 3]
function Card() {
  const [vwidth, setvwidth] = useState(0)
  const [vheight, setvheight] = useState(0)


  const updateDimensions = () => {
    let update_width = window.innerWidth;
    let update_height = window.innerHeight;
    setvwidth(update_width)
    setvheight(update_height)
  }
  /*Problema trasformare float da left se è in schermo intero a none se si riduce per eliminare problema fascia bianca */
  const [props, set] = useSpring(() => ({ xy: [0, 0], config: { mass: 10, tension: 550, friction: 140 } }))
  return (
    <div style={{ width: "100%", float: ("left"), height: "100%", marginTop: "0%" }}
      onMouseMove={({ clientX: x, clientY: y }) => set({ xy: calc(x, y) })}>
      <Tilt style={{ width: "100%", float: "left", height: "100%" }} >
        <animated.div style={{ borderRadius: "5px", willChange: "transform", fontSize: "50px" }}>
          <h1 style={{ fontSize: "40px", marginTop: "60%", marginLeft: "0%", float: "left", textShadow: "3px 3px #000000 ", color: "#C4C4C4 ", position: "absolute" }}>Fresh & Dry Pasta production tecnology </h1>
          <h1 style={{ fontSize: "50px", marginTop: "50%", marginLeft: "20%", float: "left", textShadow: "3px 3px #000000 ", color: "#C4C4C4 ", position: "absolute" }}>Food Coocking </h1>
          <h1 style={{ fontSize: "35px", marginTop: "40%", marginLeft: "40%", float: "left", textShadow: "3px 3px #000000 ", color: "#A5A5A5  ", position: "absolute" }}>Food Freezing</h1>
          <h1 style={{ fontSize: "42px", marginTop: "30%", marginLeft: "60%", float: "left", textShadow: "3px 3px #000000 ", color: "#7D7D7D", transform: "rotate(90deg)", position: "absolute" }}>Food Cooling</h1>
          <h1 style={{ fontSize: "22px", marginTop: "20%", marginLeft: "80%", float: "left", textShadow: "2px 2px #000000 ", color: "#A5A5A5  ", position: "absolute" }}>Food Cleaning</h1>
          <h1 style={{ fontSize: "40px", marginTop: "10%", marginLeft: "85%", float: "left", textShadow: "2px 2px #000000 ", color: "#A5A5A5  ", position: "absolute" }}>Textile </h1>
          <h1 style={{ fontSize: "23px", marginTop: "0%", marginLeft: "65%", float: "left", textShadow: "2px 2px #000000 ", color: "#A5A5A5  ", position: "absolute" }}>Robotized handling</h1>
          <h1 style={{ fontSize: "48px", marginTop: "65%", marginLeft: "45%", float: "left", textShadow: "3px 3px #000000 ", color: "#7D7D7D", transform: "rotate(90deg)", position: "absolute" }}>Picking</h1>
          <h1 style={{ fontSize: "33px", marginTop: "55%", marginLeft: "25%", float: "left", textShadow: "3px 3px #000000 ", color: "#A5A5A5  ", position: "absolute" }}> Water Treatment</h1>
          <h1 style={{ fontSize: "40px", marginTop: "45%", marginLeft: "5%", float: "left", textShadow: "3px 3px #000000 ", color: "#7D7D7D", position: "absolute" }}>Purification</h1>
          <h1 style={{ fontSize: "60px", marginTop: "35%", marginLeft: "15%", float: "left", textShadow: "3px 3px #000000 ", color: "#C4C4C4 ", position: "absolute" }}>Handling</h1>
          <h1 style={{ fontSize: "80px", marginTop: "25%", marginLeft: "35%", float: "left", textShadow: "3px 3px #000000 ", color: "#7D7D7D", transform: "rotate(90deg)", position: "absolute" }}>Automotive Testing</h1>
          <h1 style={{ fontSize: "60px", marginTop: "15%", marginLeft: "55%", float: "left", textShadow: "3px 3px #000000 ", color: "#A5A5A5  ", position: "absolute" }}>Stone Processing</h1>
          <h1 style={{ fontSize: "42px", marginTop: "5%", marginLeft: "75%", float: "left", textShadow: "3px 3px #000000 ", color: "#7D7D7D", transform: "rotate(90deg)", position: "absolute" }}>Stone Handling</h1>
          <h1 style={{ fontSize: "22px", marginTop: "62%", marginLeft: "75%", float: "left", textShadow: "2px 2px #000000 ", color: "#A5A5A5  ", position: "absolute" }}>Recycling packagin</h1>
          <h1 style={{ fontSize: "39px", marginTop: "57%", marginLeft: "60%", float: "left", textShadow: "2px 2px #000000 ", color: "#A5A5A5  ", transform: "rotate(90deg)", position: "absolute" }}>Packagin</h1>
          <h1 style={{ fontSize: "53px", marginTop: "52%", marginLeft: "20%", float: "left", textShadow: "2px 2px #000000 ", color: "#7D7D7D ", position: "absolute" }}>Glass</h1>
        </animated.div>
      </Tilt>
    </div>
  )
}
function Card2() {
  const [vwidth, setvwidth] = useState(0)
  const [vheight, setvheight] = useState(0)


  const updateDimensions = () => {
    let update_width = window.innerWidth;
    let update_height = window.innerHeight;
    setvwidth(update_width)
    setvheight(update_height)
  }
  /*Problema trasformare float da left se è in schermo intero a none se si riduce per eliminare problema fascia bianca */
  const [props, set] = useSpring(() => ({ xy: [0, 0], config: { mass: 10, tension: 550, friction: 140 } }))
  return (
    <div style={{ width: "100%", float: ("left"), height: "100%", marginTop: "0%" }}
      onMouseMove={({ clientX: x, clientY: y }) => set({ xy: calc(x, y) })}>
      <Tilt style={{ width: "100%", float: "left", height: "100%" }} >
        <animated.div style={{ borderRadius: "5px", willChange: "transform", fontSize: "50px" }}>
          <h1 style={{ fontSize: "80px", marginTop: "0%", left: "0%", float: "left", position: "absolute", textShadow: "3px 3px #000000 ", color: "#C4C4C4 ", transform: "rotate(90deg)" }}>PLC</h1>
          <h1 style={{ fontSize: "50px", marginTop: "5%", left: "20%", float: "left", position: "absolute", textShadow: "3px 3px #000000 ", color: "#C4C4C4 " }}>HMI</h1>
          <h1 style={{ fontSize: "35px", marginTop: "10%", left: "40%", float: "left", position: "absolute", textShadow: "3px 3px #000000 ", color: "#A5A5A5  " }}>Advanced PLC</h1>
          <h1 style={{ fontSize: "42px", marginTop: "15%", left: "60%", float: "left", position: "absolute", textShadow: "3px 3px #000000 ", color: "#7D7D7D", transform: "rotate(90deg)" }}>Monitoring</h1>
          <h1 style={{ fontSize: "22px", marginTop: "20%", left: "80%", float: "left", position: "absolute", textShadow: "2px 2px #000000 ", color: "#A5A5A5  " }}>Cam Motion</h1>
          <h1 style={{ fontSize: "19px", marginTop: "25%", left: "85%", float: "left", position: "absolute", textShadow: "2px 2px #000000 ", color: "#A5A5A5 ", transform: "rotate(90deg)" }}>Stand Alone Motion</h1>
          <h1 style={{ fontSize: "23px", marginTop: "30%", left: "65%", float: "left", position: "absolute", textShadow: "2px 2px #000000 ", color: "#A5A5A5  " }}>Trchnological PLC solutions</h1>
          <h1 style={{ fontSize: "48px", marginTop: "35%", left: "45%", float: "left", position: "absolute", textShadow: "3px 3px #000000 ", color: "#7D7D7D", transform: "rotate(90deg)" }}>Flying Cut</h1>
          <h1 style={{ fontSize: "33px", marginTop: "40%", left: "25%", float: "left", position: "absolute", textShadow: "3px 3px #000000 ", color: "#A5A5A5  " }}>Process Data tratment</h1>
          <h1 style={{ fontSize: "40px", marginTop: "45%", left: "5%", float: "left", position: "absolute", textShadow: "3px 3px #000000 ", color: "#7D7D7D" }}>SCADA</h1>
          <h1 style={{ fontSize: "60px", marginTop: "50%", left: "65%", float: "left", position: "absolute", textShadow: "3px 3px #000000 ", color: "#C4C4C4 " }}>Robotics Applications</h1>
          <h1 style={{ fontSize: "50px", marginTop: "55%", left: "35%", float: "left", position: "absolute", textShadow: "3px 3px #000000 ", color: "#C4C4C4 " }}>CPU</h1>
          <h1 style={{ fontSize: "42px", marginTop: "50%", left: "55%", float: "left", position: "absolute", textShadow: "3px 3px #000000 ", color: "#7D7D7D", transform: "rotate(90deg)" }}>Siemens</h1>
          <h1 style={{ fontSize: "22px", marginTop: "65%", left: "75%", float: "left", position: "absolute", textShadow: "2px 2px #000000 ", color: "#A5A5A5  " }}>Schneider</h1>
          <h1 style={{ fontSize: "19px", marginTop: "70%", left: "72%", float: "left", position: "absolute", textShadow: "2px 2px #000000 ", color: "#A5A5A5  ", transform: "rotate(90deg)" }}>B&R</h1>
          <h1 style={{ fontSize: "23px", marginTop: "65%", left: "62%", float: "left", position: "absolute", textShadow: "2px 2px #000000 ", color: "#A5A5A5  ", transform: "rotate(90deg)" }}>Asem</h1>
          <h1 style={{ fontSize: "48px", marginTop: "60%", left: "45%", float: "left", position: "absolute", textShadow: "3px 3px #000000 ", color: "#7D7D7D " }}>Omron</h1>
          <h1 style={{ fontSize: "33px", marginTop: "55%", left: "42%", float: "left", position: "absolute", textShadow: "3px 3px #000000 ", color: "#A5A5A5  " }}>Scl</h1>
          <h1 style={{ fontSize: "40px", marginTop: "50%", left: "32%", float: "left", position: "absolute", textShadow: "3px 3px #000000 ", color: "#7D7D7D" }}>Awl</h1>
          <h1 style={{ fontSize: "60px", marginTop: "45%", left: "80%", float: "left", position: "absolute", textShadow: "3px 3px #000000 ", color: "#C4C4C4 " }}>Kop</h1>
          <h1 style={{ fontSize: "80px", marginTop: "40%", left: "12%", float: "left", position: "absolute", textShadow: "3px 3px #000000 ", color: "#C4C4C4", transform: "rotate(90deg)" }}>Ladder</h1>
          <h1 style={{ fontSize: "50px", marginTop: "35%", left: "2%", float: "left", position: "absolute", textShadow: "3px 3px #000000 ", color: "#C4C4C4 " }}>React</h1>
          <h1 style={{ fontSize: "35px", marginTop: "30%", left: "8%", float: "left", position: "absolute", textShadow: "3px 3px #000000 ", color: "#A5A5A5  ", transform: "rotate(90deg)" }}>Javascript</h1>
          <h1 style={{ fontSize: "42px", marginTop: "25%", left: "18%", float: "left", position: "absolute", textShadow: "3px 3px #000000 ", color: "#7D7D7D", transform: "rotate(90deg)" }}>HTML</h1>
          <h1 style={{ fontSize: "22px", marginTop: "20%", left: "38%", float: "left", position: "absolute", textShadow: "2px 2px #000000 ", color: "#A5A5A5  " }}>CSS</h1>
          <h1 style={{ fontSize: "19px", marginTop: "15%", left: "58%", float: "left", position: "absolute", textShadow: "2px 2px #000000 ", color: "#A5A5A5  " }}>C#</h1>
          <h1 style={{ fontSize: "23px", marginTop: "10%", left: "68%", float: "left", position: "absolute", textShadow: "2px 2px #000000 ", color: "#A5A5A5  " }}>WWB</h1>
          <h1 style={{ fontSize: "48px", marginTop: "0%", left: "78%", float: "left", position: "absolute", textShadow: "3px 3px #000000 ", color: "#7D7D7D", transform: "rotate(90deg)" }}>Visual Basic</h1>
          <h1 style={{ fontSize: "33px", marginTop: "0%", left: "68%", float: "left", position: "absolute", textShadow: "3px 3px #000000 ", color: "#A5A5A5  " }}>Java</h1>
          <h1 style={{ fontSize: "22px", marginTop: "24%", left: "48%", float: "left", position: "absolute", textShadow: "2px 2px #000000 ", color: "#A5A5A5  " }}>Opcua</h1>
          <h1 style={{ fontSize: "19px", marginTop: "26%", left: "28%", float: "left", position: "absolute", textShadow: "2px 2px #000000 ", color: "#C4C4C4 ", transform: "rotate(90deg)" }}>ModBus</h1>
          <h1 style={{ fontSize: "23px", marginTop: "50%", left: "8%", float: "left", position: "absolute", textShadow: "2px 2px #000000 ", color: "#A5A5A5  " }}>ProfiNet</h1>
          <h1 style={{ fontSize: "48px", marginTop: "68%", left: "0%", float: "left", position: "absolute", textShadow: "3px 3px #000000 ", color: "#7D7D7D", transform: "rotate(90deg)" }}>ProfiSafe</h1>
          <h1 style={{ fontSize: "33px", marginTop: "55%", left: "11%", float: "left", position: "absolute", textShadow: "3px 3px #000000 ", color: "#A5A5A5  " }}>ProfiBus</h1>
          <h1 style={{ fontSize: "40px", marginTop: "24%", left: "41%", float: "left", position: "absolute", textShadow: "3px 3px #000000 ", color: "#7D7D7D" }}>Node.JS</h1>
          <h1 style={{ fontSize: "60px", marginTop: "46%", left: "90%", float: "left", position: "absolute", textShadow: "3px 3px #000000 ", color: "#C4C4C4 " }}>SQL</h1>
          <h1 style={{ fontSize: "30px", marginTop: "20%", left: "46%", float: "left", position: "absolute", textShadow: "3px 3px #000000 ", color: "#C4C4C4 ", transform: "rotate(90deg)" }}>Process Probe-Instruments Knowledge</h1>
        </animated.div>
      </Tilt>
    </div>
  )
}
function Media() {
  useEffect(() => {
    window.scrollTo(0, 0);

    updateDimensions();
    window.addEventListener("resize", updateDimensions.bind(this));

    // Specify how to clean up after this effect:
    return () => {
      window.removeEventListener("resize", updateDimensions.bind(this))
    }
  }, []);
  useEffect(() => {
    const interval = setInterval(() => {
      setCounter(counter => counter >= 3 ? counter * 0 : counter + 1)
      var temp = eCarousel1Dimensions.current.offsetHeight * 0.9
      setH(temp)
      var temp = eCarousel2Dimensions.current.offsetHeight * 0.9
      setH2(temp)

    }, 1000);
    // Specify how to clean up after this effect:
    return () => {
      clearInterval(interval);
    }
  }, []);
  const [vwidth, setvwidth] = useState(0)
  const [vheight, setvheight] = useState(0)
  const [eCarousel1Dimensions, seteCarousel1Dimensions] = useState(React.createRef())
  const [eCarousel2Dimensions, seteCarousel2Dimensions] = useState(React.createRef())
  const [movingCar, setmovingCar] = useState(0)
  const commentSection4 = useRef(null);
  const history = useHistory();
  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 1 },
    { width: 768, itemsToShow: 1 },
    { width: 1200, itemsToShow: 1 }
  ];
  const updateDimensions = () => {
    let update_width = window.innerWidth;
    let update_height = window.innerHeight;
    setvwidth(update_width)
    setvheight(update_height)
  }
  const [counter, setCounter] = useState(0);
  const [offsetY, setOffsetY,] = useState(0)
  const [H, setH] = useState(10)
  const [H2, setH2] = useState(10)
 
  const gotoCommentSection = () => window.scrollTo({
    top: (isMobile ? "650" : "1000"),
    behavior: "smooth"
  });
  const gotoCommentSection1 = () => window.scrollTo({
    top: (isMobile ? "1450" : "2150"),
    behavior: "smooth"
  });
  const gotoCommentSection2 = () => window.scrollTo({
    top: (isMobile ? "2280" : "3200"),
    behavior: "smooth"
  });  
  const gotoCommentSection4 = () => window.scrollTo({
    top: (isMobile ? "5400" : "6750"),//commentSection4.current.offsetTop,
    behavior: "smooth"
  });
  const gotoCommentSection5 = () => window.scrollTo({
    top: (isMobile ? "3000" : "4235"),
    behavior: "smooth"
  });
  const gotoCommentSection6 = () => window.scrollTo({
    top: (isMobile ? "4430" : "5670"),
    behavior: "smooth"
  });
  const gotoCommentSection3 = () => window.scrollTo({
    top: 0,
    behavior: "smooth"
  });
  const [props, set, stop] = useSpring(() => ({ opacity: 0.5 }))
  set({ opacity: "toggle" ? 1 : 0 })
  stop()
  const portaSu = () => {
    gotoCommentSection3()
    refrescia()
    window.scrollTo(0, 0);
  }
  const refrescia = () => {
    window.location.reload()
  }
  const handleOnClick = () => window.location.replace("https://www.verticale.net/myazienda_scheda.php?dominio=sapitec.net&ragsoc=Sapitec");
  const Hh = H + "px";
  const Hh2 = H2 + "px";

  return (
    <div style={{ width: "100%" }} >
      <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"></link>

      <body style={{ height: "5200px", width: "100%", position: "relative", marginTop: "0%", backgroundColor: "black" }} >
        {/*-------------------NAVBAR--------------- */}
        <nav>
        <div style={{ height: "5%" }}>
        <ReactBootStrap.Navbar collapseOnSelect expand="lg"  variant="dark" fixed="top"
             style={{ backgroundColor: "#000535", 
             width: "100%", position: "fixed" }} >
              <Link to='/' style={{ backgroundImage: `url(${logo})`,
               backgroundSize: "contain", backgroundRepeat: "no-repeat",
                height: "20px" }}><image Link className="logo" ></image></Link>
              <ReactBootStrap.Navbar.Toggle aria-controls="responsive-navbar-nav" />
              <ReactBootStrap.Navbar.Collapse id="responsive-navbar-nav">
                <ReactBootStrap.Nav className="mr-auto">|               
                  <Link className='HomeT' onClick={gotoCommentSection} >Chi Siamo</Link>
                  <Link className='HomeT' onClick={gotoCommentSection1}  >Progetti</Link>
                  <Link className='HomeT' onClick={gotoCommentSection2}  >Automazioni</Link>
                  <Link className='HomeT' onClick={gotoCommentSection5}  >News</Link>
                  <Link className='HomeT' onClick={gotoCommentSection6}  >Partner</Link>
                  <Link className='HomeT' onClick={gotoCommentSection4}  >Contatti</Link>
                  <Link className='HomeT' to='/YouDom' >YouDom
                  < div style={{
                      marginTop: "4px", marginLeft: "2px", float: "right",
                      backgroundImage: `url(${Youdompng})`, backgroundSize: "contain", backgroundRepeat: "no-repeat", width: "20px", height: "20px"
                    }} />
                  </Link>
                  <Link className='HomeT' ><a onClick={handleOnClick}>
                    < div style={{
                      marginTop: "-5px", marginLeft: "2px", float: "right",
                      backgroundImage: `url(${ImmagineBackground5})`, backgroundSize: "contain", backgroundRepeat: "no-repeat", width: "65px", height: "30px"
                    }} /></a>
                  </Link>
                  <Link className='HomeT' to='/Por'>
                    < div style={{
                      marginTop: "4px", marginLeft: "2px", float: "right",
                      backgroundImage: `url(${ImmaginePor})`, backgroundSize: "cover", backgroundRepeat: "no-repeat", width: "90px", height: "20px"
                    }} />
                  </Link>
                  <ReactBootStrap.NavDropdown.Divider />
                </ReactBootStrap.Nav>
              </ReactBootStrap.Navbar.Collapse>
            </ReactBootStrap.Navbar>
          </div>
        </nav>
        {/*-------------------Sezione 1: TITOLO MAPPA E CONEGGI--------------- */}
        <div style={{
          position: "relative", width: "100%", height: (isMobile ? "720px" : "1056px"),
          backgroundImage: `url(${ImmagineBackground})`, marginTop: "0%",
          backgroundRepeat: "no-repeat", backgroundSize: "cover"
        }}>
          {/*Mappa + Marker */}
          <div style={{
            width: "100%",
            height: "25%",
            position: "absolute",
            top: (isMobile ? "10%" : "5%"),
            left: (isMobile ? "2%" : "0%"),
          }}>
            <MapChart />
          </div>
          {/*Animazione SAPITEC */}
          <div style={{
            width: "60%", height: "15%", position: "absolute", top: (isMobile ? "0%" : "5%"),
            left: (isMobile ? "30%" : "32%")
          }}><TextAnim /></div>
          {/*Testi Contatori*/}
          <div style={{
            width: "100%", height: "25%", position: "absolute", top: (isMobile ? "20%" : "20%"),
            left: "0%"
          }}>
            <a style={{
              textAlign: 'center', position: "absolute", top: (isMobile ? "10%" : "15%"),
              left: (isMobile ? "44%" : "42%"), color: 'white', fontSize: '4vw', fontWeight: 'bold',
              textShadow: "3px 3px  #003470"
            }}
              data-aos="fade-up"            >
              Più di :</a>
            {/* N1 */}
            <div style={{ width: "10%", float: "left", marginLeft: "10%", marginTop: "5%" }}
              data-aos="fade-up"
              data-aos-offset="-1000"
              data-aos-delay="400"
              data-aos-duration="2000"
              data-aos-easing="ease-in-out"
              className='Animated'>
              <CountUp className='content'
                style={{ fontSize: "5vw", color: 'white', fontWeight: 'bold', textShadow: "3px 3px #003470", marginTop: "-30%" }}
                start={0}
                end={200}
                duration={6}
                delay={0.5}>
              </CountUp>
              <a style={{ fontSize: '2vw', color: 'white', textAlign: 'center', float: "left" }}>Pannelli<br />Operativi<br />Online</a>
            </div>
            {/* N2 */}
            <div style={{ width: "10%", float: "left", alignItems: "center", marginLeft: "5%", marginTop: "20%" }}
              data-aos="fade-up"
              data-aos-offset="-1000"
              data-aos-delay="400"
              data-aos-duration="2000"
              data-aos-easing="ease-in-out"
              className='Animated'>
              <CountUp className='content'
                style={{ fontSize: "5vw", color: 'white', fontWeight: 'bold', textShadow: "3px 3px  #003470 ", marginTop: "-30%" }}
                start={0}
                end={200}
                duration={6}
                delay={0.5}>
              </CountUp>
              <a style={{ fontSize: '2vw', color: 'white', textAlign: 'center', float: "left" }}>Macchinari/<br />impianti<br /> d'automazione</a>
            </div>
            {/* N3 */}
            <div style={{ width: "10%", float: "left", textAlign: "center", marginTop: "20%", marginLeft: "25%" }}
              data-aos="fade-up"
              data-aos-offset="-1000"
              data-aos-delay="400"
              data-aos-duration="2000"
              data-aos-easing="ease-in-out"
              className='Animated'>
              <CountUp className='content'
                style={{ fontSize: "5vw", color: 'white', fontWeight: 'bold', textShadow: "3px 3px  #003470 ", }}
                start={0}
                end={100}
                duration={6}
                delay={0.5}>
              </CountUp>
              <a style={{ fontSize: '2vw', color: 'white', float: "left" }} >Applicazioni <br /> Speciali <br />Sviluppate</a>
            </div>
            {/* N4 */}
            <div style={{ width: "10%", float: "left", textAlign: "center", marginTop: "7%", marginLeft: "10%" }}
              data-aos="fade-up"
              data-aos-offset="-1000"
              data-aos-delay="400"
              data-aos-duration="2000"
              data-aos-easing="ease-in-out"
              className='Animated'>
              <CountUp className='content'
                style={{ fontSize: "5vw", color: 'white', fontWeight: 'bold', textShadow: "3px 3px  #003470 " }}
                start={0}
                end={900}
                duration={3}
                delay={2}>
              </CountUp>
              <a style={{ fontSize: '2vw', color: 'white', textAlign: 'center', float: "left" }} >Inverter e <br /> Drive <br /> Operativi</a>
            </div>

          </div>
          {/*Logo Fiera */}
          {/*  <div data-aos="zoom-out"
            data-aos-offset="-1000"
            data-aos-delay="0"
            data-aos-duration="2000"
            data-aos-easing="ease-in-out"
            style={{ top: "1%", float: "left", left: "1%", borderColor: "blue", border: "1px", position: "absolute", }}
          >
            <a href="https://www.verticale.net/myazienda_scheda.php?dominio=sapitec.net&ragsoc=Sapitec" target="_blank"
              style={{ height: "100%", width: "100%" }}>
             <img src={ImmagineBackground5} border="0" alt="" />
             
         
             </a>
          </div>*/}
        </div>
        {/*-------------------Sezione 2: STORIA SAPITEC --------------- */}
        <div style={{
          position: "relative", width: "100%", height: isMobile ? "850px" : "1056px",
          backgroundImage: `url(${BackgroundRovescio})`,
          backgroundRepeat: "no-repeat", backgroundSize: "cover"
        }}>
          {/*Parole sfondo */}
          <div style={{
            position: "absolute", marginTop: "5%", left: "0%",
            width: "90%", alignContent: "center"
          }}>
            {isMobile ? null : <Card />}</div>
          {/*Tabella 1 */}
          <div style={{
            width: "100%", position: "absolute",
            top: (isMobile ? "5%" : "15%"),
            left: ("0%")
          }}>
            <h3 data-aos={(isMobile ? "fade-right" : "fade-up")}
              style={{
                position: "absolute",
                width: (isMobile ? "90%" : "40%"),
                float: "left", backgroundColor: "rgb(0, 0, 0, 60%)",
                marginTop: (isMobile ? "-10%" : "0%"),
                marginLeft: (isMobile ? "5%" : "5%"),
                boxShadow: "0px 12px 18px -6px rgba(0,0,0,0,.3)",
                borderRadius: "10px 10px 10px 10px"
              }}>
              <h2 style={{ backgroundColor: "rgba(100, 148, 237, 0.555)", borderRadius: "10px 10px 0px 0px", textAlign: "center" }}>STORIA SAPITEC</h2>
              <div style={{
                fontSize: (isMobile ? "3vw" : "1vw"), textAlign: "left", width: "80%", height: "200%", marginLeft: "10%", color: "lightgray", marginTop: "5%", textJustify: "inter-word"
                , textAlign: "justify"
              }}>
                L'azienda Sapitec nasce negli anni 2000 da esperti di automazione industriale con decenni di esperienza nel settore.
                Da quando è nata, l'azienda si propone di portare ai suoi clienti applicazioni semplici ed efficaci, avvalendosi delle più recenti tecnologie presenti nel mercato.
                Proprio per essere sempre al passo con le tecnologie in costante evoluzione, la filosofia di Sapitec è di appoggiarsi a collaboratori giovani, con menti elastiche e improntate al futuro.
                Il nome SapiTec rispecchia la nostra "mission": Software Automation Partner Integrator Technologies , ovvero ci proponiamo come integratori affidabili di software per automazione industriale e risolutori di problematiche di automazione avanzata.</div>
              <br />
              <br />
            </h3>
          </div >
          {/*Video */}
          <div data-aos={(isMobile ? "fade-right" : "fade-up")}>
            <div style={{
              position: "absolute", marginTop: (isMobile ? "100%" : "0%"),
              marginLeft: (isMobile ? "5%" : "50%"), width: isMobile ? "100%" : "45%", height: "50%"
            }}>
              <ReactPlayer width={isMobile ? "90%" : "100%"}
                height={isMobile ? "180px" : "320px"}
                url="https://www.youtube.com/watch?v=jbGYoN3Wm08"
              />
            </div>
          </div>
          {/*Video */}
          <div data-aos={(isMobile ? "fade-right" : "fade-up")}>
            <div style={{
              position: "absolute", marginTop: (isMobile ? "155%" : "350px"),
              marginLeft: (isMobile ? "5%" : "50%"), width: isMobile ? "100%" : "45%", height: "50%"
            }}>
              <ReactPlayer width={isMobile ? "90%" : "100%"}
                height={isMobile ? "180px" : "320px"}
                url="https://www.youtube.com/watch?v=WNeljXInL1w&t=24s"
              />
            </div>
          </div>
        </div>
        {/*-------------------Sezione 3: SKILLS--------------- */}
        <div style={{
          position: "relative", width: "100%", height: isMobile ? "900px" : "1056px",
          backgroundImage: `url(${ImmagineBackground})`,
          backgroundRepeat: "no-repeat", backgroundSize: "cover"
        }} >
          {/*Parole sfondo 2 */}
          <div style={{
            position: "absolute", marginTop: "5%", left: "0%",
            width: "90%", alignContent: "center"
          }}>
            {isMobile ? null : <Card2 />}</div>
          {/*Tabella 2 */}
          <div style={{
            width: "100%", position: "absolute",
            top: (isMobile ? "5%" : "20%"),
            left: "0%"
          }}>
            <h3 data-aos={(isMobile ? "fade-right" : "fade-up")}
              style={{
                position: "absolute",
                width: (isMobile ? "90%" : "30%"),
                float: "left", backgroundColor: "rgb(0, 0, 0, 60%)",
                marginTop: (isMobile ? "30%" : "-7%"),
                right: isMobile ? "5%" : "2%", boxShadow: "0px 12px 18px -6px rgba(0,0,0,0,.3)",
                borderRadius: "10px 10px 10px 10px"
              }}>
              <h2 style={{
                backgroundColor: "rgba(100, 148, 237, 0.555)",
                borderRadius: "10px 10px 0px 0px", textAlign: "center"
              }}>SKILLS</h2>
              <div style={{
                fontSize: (isMobile ? "3vw" : "1vw"), textJustify: "inter-word"
                , textAlign: "justify", width: "80%", height: "190%",
                marginLeft: "10%", color: "lightgray", marginTop: "5%"
              }}>
                Come integratori di software di automazione industriale utilizziamo strumenti  di ultima generazione e tecnologie  avanzate per la programmazione e la realizzazione di prodotti d'automazione industriale di alto livello: PLC, Motion, Robotica, Safety, Supervisione, HMI, trattamento dati.
                Il nostro team è specializzato nell'implementazione delle più usate marche di automazione, quali:
                -Siemens -Schneider -B&R - Fanuc, Asem Non trascuriamo però la programmazione di alto livello, facendo applicazioni web customizzate. Il nostro team opera con HTML, Js, Css, ReactJs per il Frontend , C#, NodeJs per il Backend e si interfaccia con protocolli come ModBus, OPCUA, Profinet e derivati. Questo ci permette di offrire soluzioni verticali partendo dalla base, ossia la programmazione del PLC, alla vetta, ovvero il trattamento e l'esportazione dei dati di produzione in linea con Industria 4.0.
           </div>
              <br />
              <br />
            </h3>
          </div>
          {/* */}
          {/*Popplio*/}
          <div style={{
            marginTop: (isMobile ? "0%" : "37%"),
            position: "absolute",
            marginLeft: (isMobile ? "30%" : "78%"),
            height: (isMobile ? "150px" : "200px"),
            width: (isMobile ? "150px" : "200px")
          }}
            data-aos="zoom-in">
            <Popplio />
          </div>
          {/*Carosello*/}
          <div
            ref={eCarousel1Dimensions}
            style={{
              width: (isMobile ? "90%" : "50%"),
              height: (isMobile ? "220px" : "500px"),
              float: "left",
              marginLeft: (isMobile ? "5%" : "5%"),
              marginTop: (isMobile ? "150%" : "10%"),
              position: "absolute",
              backgroundColor: "black",
              border: "5px solid grey",
              borderRadius: "25px 25px 25px 25px",
            }}>

            <Carousel style={{
              marginTop: "1.5%", marginLeft: "6%", position: "absolute",
              width: "85%", height: Hh, textAlign: "center"
            }}>
              <Carousel.Item interval={1000} >
                <img
                  className="d-block w-auto"
                  src={carosello1Img1}
                  alt="First slide"
                  width="auto"
                  height={Hh}
                  style={{ borderRadius: "10px 10px 10px 10px" }}
                />
              </Carousel.Item>
              <Carousel.Item interval={500}>
                <img
                  className="d-block w-auto"
                  src={carosello1Img2}
                  alt="Third slide"
                  width="auto"
                  borderRadius="10px 10px 10px 10px"
                  height={Hh}
                  style={{ borderRadius: "10px 10px 10px 10px" }}
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="d-block w-auto"
                  src={carosello1Img3}
                  alt="Third slide"
                  width="auto"
                  borderRadius="10px 10px 10px 10px" x
                  height={Hh}
                  style={{ borderRadius: "10px 10px 10px 10px" }}

                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="d-block w-auto"
                  src={carosello1Img4}
                  alt="Third slide"
                  width="auto"
                  borderRadius="10px 10px 10px 10px" x
                  height={Hh}
                  style={{ borderRadius: "10px 10px 10px 10px" }}

                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="d-block w-auto"
                  src={carosello1Img5}
                  alt="Third slide"
                  width="auto"
                  borderRadius="10px 10px 10px 10px" x
                  height={Hh}
                  style={{ borderRadius: "10px 10px 10px 10px" }}

                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="d-block w-auto"
                  src={carosello1Img6}
                  alt="Third slide"
                  width="auto"
                  borderRadius="10px 10px 10px 10px" x
                  height={Hh}
                  style={{
                    borderRadius: "10px 10px 10px 10px",
                  }}
                />
              </Carousel.Item>
            </Carousel>
          </div>
        </div>
        {/*-------------------Sezione 4: AUTOMAZIONI--------------- */}
        <div style={{
          position: "relative", width: "100%", height: isMobile ? "720px" : "1056px", backgroundImage: `url(${BackgroundRovescio})`,
          backgroundRepeat: "no-repeat", backgroundSize: "cover"
        }} >
          <div style={{
            position: "absolute", marginTop: "5%", left: "0%", width: "80%",
            alignContent: "center"
          }}>
            {isMobile ? null : <Card />}
          </div>

          {/*Casella Testo 3 */}
          <div style={{
            width: "100%", position: "absolute",
            top: (isMobile ? "5%" : "28%"),
            left: (isMobile ? "0%" : "-40%")
          }}>
            <h3 data-aos={(isMobile ? "fade-right" : "fade-up")}
              style={{
                position: "absolute",
                width: (isMobile ? "90%" : "30%"),
                float: "left", backgroundColor: "rgb(0, 0, 0, 60%)",
                marginTop: (isMobile ? "0%" : "0%"),
                marginLeft: (isMobile ? "5%" : "50%"), boxShadow: "0px 12px 18px -6px rgba(0,0,0,0,.3)",
                borderRadius: "10px 10px 10px 10px"
              }}>
              <h2 style={{
                backgroundColor: "rgba(100, 148, 237, 0.555)",
                borderRadius: "10px 10px 0px 0px", textAlign: "center"
              }}>Automazioni</h2>
              <div style={{
                fontSize: (isMobile ? "3vw" : "1vw"),
                textAlign: "center", width: "80%", height: "200%",
                marginLeft: "10%", color: "lightgray", marginTop: "5%", textJustify: "inter-word"
                , textAlign: "justify"
              }}>
                Ci rivolgiamo principalmente ai costruttori di macchinari industriali
                ma anche a utilizzatori finali, che si affidano alla nostra esperienza nella programmazione e integrazione ci componentistica d'automazione industriale. Offriamo copertura completa nel di progettazione e stesura software e progettazione ed assemblaggio elettrico, fornendo quindi un pacchetto completo che permette alle vostre idee di prendere vita.
                Offriamo inoltre servizio di service after sale,
               mediante assistenza tecnica e teleassistenza</div>
              <br />
              <br />
            </h3>
          </div>
          {/*Carosello*/}
          <div
            ref={eCarousel2Dimensions}
            style={{
              width: (isMobile ? "90%" : "50%"),
              height: (isMobile ? "220px" : "500px"),
              float: "left",
              marginLeft: (isMobile ? "5%" : "45%"),
              marginTop: (isMobile ? "100%" : "14%"),
              position: "absolute",
              backgroundColor: "black",
              border: "5px solid grey",
              borderRadius: "25px 25px 25px 25px",
            }}>

            <Carousel style={{
              marginTop: "1.5%", marginLeft: "6%", position: "absolute",
              width: "88%", height: Hh2
            }}>
              <Carousel.Item interval={1000}>
                <img
                  className="d-block w-auto"
                  src={carosello2Img1}
                  alt="First slide"
                  width="auto"
                  height={Hh2}
                  style={{ borderRadius: "10px 10px 10px 10px" }}
                />
              </Carousel.Item>
              <Carousel.Item interval={500}>
                <img
                  className="d-block w-auto"
                  src={carosello2Img2}
                  alt="Third slide"
                  width="auto"
                  borderRadius="10px 10px 10px 10px"
                  height={Hh2}
                  style={{ borderRadius: "10px 10px 10px 10px" }}
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="d-block w-auto"
                  src={carosello2Img3}
                  alt="Third slide"
                  width="auto"
                  borderRadius="10px 10px 10px 10px" x
                  height={Hh2}
                  style={{ borderRadius: "10px 10px 10px 10px" }}

                />
              </Carousel.Item>
              <Carousel.Item interval={1000}>
                <img
                  className="d-block w-auto"
                  src={carosello2Img4}
                  alt="First slide"
                  width="auto"
                  height={Hh2}
                  style={{ borderRadius: "10px 10px 10px 10px" }}
                />
              </Carousel.Item>
              <Carousel.Item interval={1000}>
                <img
                  className="d-block w-auto"
                  src={carosello2Img9}
                  alt="First slide"
                  width="auto"
                  height={Hh2}
                  style={{ borderRadius: "10px 10px 10px 10px" }}
                />
              </Carousel.Item>
              <Carousel.Item interval={1000}>
                <img
                  className="d-block w-auto"
                  src={carosello2Img6}
                  alt="First slide"
                  width="auto"
                  height={Hh2}
                  style={{ borderRadius: "10px 10px 10px 10px" }}
                />
              </Carousel.Item>
              <Carousel.Item interval={1000}>
                <img
                  className="d-block w-auto"
                  src={carosello2Img7}
                  alt="First slide"
                  width="auto"
                  height={Hh2}
                  style={{ borderRadius: "10px 10px 10px 10px" }}
                />
              </Carousel.Item>
              <Carousel.Item interval={1000}>
                <img
                  className="d-block w-auto"
                  src={carosello2Img8}
                  alt="First slide"
                  width="auto"
                  height={Hh2}
                  style={{ borderRadius: "10px 10px 10px 10px" }}
                />
              </Carousel.Item>
              <Carousel.Item interval={1000}>
                <img
                  className="d-block w-auto"
                  src={carosello2Img5}
                  alt="First slide"
                  width="auto"
                  height={Hh2}
                  style={{ borderRadius: "10px 10px 10px 10px" }}
                />
              </Carousel.Item>
              <Carousel.Item interval={1000}>
                <img
                  className="d-block w-auto"
                  src={carosello2Img10}
                  alt="First slide"
                  width="auto"
                  height={Hh2}
                  style={{ borderRadius: "10px 10px 10px 10px" }}
                />
              </Carousel.Item>
            </Carousel>
          </div>
        </div>
        {/*-------------------Sezione 5: TIMELINE--------------- */}
        <div style={{
          position: "relative", width: "100%", height: "1440px", backgroundImage: `url(${ImmagineBackground})`,
          backgroundRepeat: "no-repeat", backgroundSize: "cover"
        }}>
          <VerticalTimeline>
            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              contentStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
              contentArrowStyle={{ borderRight: '5px solid  rgb(33, 150, 243)' }}
              date="Dicembre 2020"
              iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
            >
              <h3 className="vertical-timeline-element-title">Pubblicazione Materiale YouDom</h3>
              <h4 className="vertical-timeline-element-subtitle">Applicazione Domotica</h4>
              <p>
                Prodotto per utilizzare al meglio le potenzialità della tecnologia domotica
           </p>
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              date="Novembre 2020"
              iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}

            >
              <h3 className="vertical-timeline-element-title">Pubblicazione portale Verticale.net</h3>
              <h4 className="vertical-timeline-element-subtitle">Upload documentazione</h4>
              <p>
                Pubblicazione contenuti
    </p>
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              date="Novembre 2020"
              iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}

            >
              <h3 className="vertical-timeline-element-title">Partecipazione "HOME AND BUILDING"</h3>
              <h4 className="vertical-timeline-element-subtitle">Fiera per Industria</h4>
              <p>
                Esposizione dell'applicazione Youdom
    </p>
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              date="Ottobre 2020"
              iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}

            >
              <h3 className="vertical-timeline-element-title">Aggiornamento Sito Internet</h3>
              <h4 className="vertical-timeline-element-subtitle">Web Page</h4>
              <p>
                Sito Web creato con React, CSS, HTML e Javascript
    </p>
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              date="Settembre 2020"
              iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}

            >
              <h3 className="vertical-timeline-element-title">Bando POR FESR</h3>
              <h4 className="vertical-timeline-element-subtitle">Progetto 2014-2020</h4>
              <p>
                Partecipato con successo al progetto POR FESR della Regione Veneto
    </p>
            </VerticalTimelineElement>
            <VerticalTimelineElement
              iconStyle={{ background: 'rgb(16, 204, 82)', color: '#fff' }}
            />
          </VerticalTimeline>
        </div>
        {/*-------------------Sezione 6: PARTHNER--------------- */}
        <div style={{
          position: "relative", width: "100%", flexDirection: "row",
          height: (isMobile ? "960px" : "1056px"),
          backgroundImage: `url(${BackgroundRovescio})`,
          backgroundRepeat: "no-repeat", backgroundSize: "cover"
        }}>
          <div style={{
            width: "100%", height: "auto", marginTop: "0.0%", borderStyle: "solid",
            borderWidth: "20px", borderLeft: "0px", borderRight: "0px", borderColor: "white"
          }}>
            <img src={partnershipS} alt="Nature" className="responsive"></img>
          </div>
          <div style={{ width: "100%", height: "25%", marginTop: "5%", alignItems: "flex-start" }}>
            <div style={{ marginTop: "5%", width: "250px", height: "150px", marginLeft: isMobile ? "15%" : "5%", backgroundImage: `url(${logoPartner1})`, backgroundSize: "100% 100%", backgroundRepeat: "no-repeat", float: "left", borderRadius: "10px 10px 10px 10px" }}></div>
            <div style={{ marginTop: "5%", width: "250px", height: "150px", marginLeft: isMobile ? "15%" : "5%", backgroundImage: `url(${logoPartner2})`, backgroundSize: "100% 100%", backgroundRepeat: "no-repeat", float: "left", borderRadius: "10px 10px 10px 10px" }}></div>
            <div style={{ marginTop: "5%", width: "250px", height: "150px", marginLeft: isMobile ? "15%" : "5%", backgroundImage: `url(${logoPartner3})`, backgroundSize: "100% 100%", backgroundRepeat: "no-repeat", float: "left", borderRadius: "10px 10px 10px 10px" }}></div>
            <div style={{ marginTop: "5%", width: "250px", height: "150px", marginLeft: isMobile ? "15%" : "5%", backgroundImage: `url(${logoPartner4})`, backgroundSize: "100% 100%", backgroundRepeat: "no-repeat", float: "left", borderRadius: "10px 10px 10px 10px" }}></div>
          </div>
        </div>
        {/*-------------------Sezione 7: FOOTER--------------- */}
        <div style={{
          width: "100%", height: (isMobile ? "1150px" : "960px"), marginTop: "0%",
          color: " rgb(144, 148, 148)", display: "flex", position: "relative",
          flexDirection: (isMobile ? "column" : "row"),
          backgroundImage: `url(${ImmagineBackground4})`,
          backgroundRepeat: "no-repeat", backgroundSize: "cover"
        }}
          ref={commentSection4}>
          <div style={{
            marginLeft: "2%", marginTop: "5%", float: "left",
            width: (isMobile ? "96%" : "56%"), height: "700px", borderColor: "black",
            borderRadius: "0px 0px 0px 0px ", border: "1px solid  black"
          }}>
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2882.4783752836674!2d11.814709567344659!3d45.66909523864854!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4778d58c51f93495%3A0x9243dfd0fe53ac12!2sSapiTec%20Srl!5e1!3m2!1sit!2sit!4v1606141154500!5m2!1sit!2sit"
              style={{
                width: "100%", height: "100%", frameborder: "0", style: "border:0px",
                allowfullscreen: "", ariaHidden: "false", tabIndex: "0"
              }} ></iframe>
          </div>
          <div style={{
            marginLeft: "2%", marginTop: "5%", float: "left",
            borderColor: "black",
            borderRadius: "0px 0px 0px 0px ", border: "1px solid  black", color: "black",
            height: (isMobile ? "400px" : "700px"),
            width: (isMobile ? "96%" : "38%")
          }}>
            <div style={{ alignItems: "center", marginLeft: "1%" }}><h5>Info</h5>
              <a href="https://www.google.it/maps/place/Via+Enrico+Mattei,+35015+Galliera+Veneta+PD/@45.6695592,11.815257,187m/data=!3m1!1e3!4m5!3m4!1s0x4778d5d9044e4455:0x9c039314d83ebe5f!8m2!3d45.6697888!4d11.8156988">
                Posizione</a>
              <br />
              <a>Sede Legale: via Cervan 39 - 31033 - Castelfranco Veneto (TV) </a>
              <br />
              <a >Sede operativa: via L. da Vinci 47/A - 35015 - Galliera Veneta (PD)</a>
              <br />
              <br />
              <br />
              <br />
              <h5 >Email</h5>
              <a href="mailto: info@sapitec.net">Contattaci</a>
              <br />
              <a>amministrazione: admin@sapitec.net </a>
              <br />
              <a >Posta elettronica certificata: sapitecsrl@legalmail.it</a>
              <br />
              <a >Info: info@sapitec.net</a>
            </div>

            {/*  <form method="post" name="contact_form" action="contact-form-handler.php"> 
              <br/>Your Name: 
              <input type="text" name="email"/> Message:
              <input type="text" name="name"/><br/> Email Address:
              <textarea name="message"></textarea><br/>
              <input type="submit" value="Submit"/>
            </form>  */}
          </div>
        </div>
        {/*---------------------------------- */}
        <div style={{ float: "left", marginBottom: "0%", backgroundColor: "black", position: "absolute", width: "100%", textAlign: "center" }}>
          <hr style={{ borderWidth: "0px", color: "white", width: "100%", height: "2px", backgroundColor: "white", marginTop: "0%" }}></hr>
          <a style={{ color: "white" }}>  <p className="copyright" style={{ marginTop: "0%", textAlign: "center" }}>
            &copy; {new Date().getFullYear()}{" "}
          Powered by {" "}
            <a href="http://sapitec.net">
              Sapitec  {" "}
            </a>| Telefono +39 0423 1950488  CF/PI 05084310266 - SDI. M5UXR1 - last update 29/04/2021
           </p>
          </a>
        </div>
      </body >
    </div >
  );
}
export default App;