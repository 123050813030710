import React from 'react'

function TextAnim() {
    return (
        <div>
<svg width="60%" height="262" viewBox="0 0 384 62" fill="none" xmlns="http://www.w3.org/2000/svg" className="TestoSSS" >
<g filter="url(#filter0_d)" className="TestoSSS">
<mask id="path-1-outside-1" maskUnits="userSpaceOnUse" x="4.73438" y="0.453125" width="376" height="54" fill="black" >
<rect fill="white" x="4.73438" y="0.453125" width="376" height="54"/>
<path d="M51.125 9.20312H16.0625V23.0312H47.2812C51.6875 23.0312 54.7031 23.8438 56.3281 25.4688C57.9531 27.0938 58.7656 30.1719 58.7656 34.7031V40.2812C58.7656 44.8438 57.9688 47.9375 56.375 49.5625C54.7812 51.1875 51.75 52 47.2812 52H18.3594C13.8594 52 10.7969 51.1875 9.17188 49.5625C7.54688 47.9062 6.73438 44.8125 6.73438 40.2812V38.9219L14.4219 37.1406V45.1094H51.3125V30.2031H20.1875C15.6875 30.2031 12.6406 29.3906 11.0469 27.7656C9.45312 26.1094 8.65625 23.0156 8.65625 18.4844V14.1719C8.65625 9.64062 9.45312 6.5625 11.0469 4.9375C12.6406 3.28125 15.6875 2.45312 20.1875 2.45312H46.5781C50.9844 2.45312 54.0156 3.26562 55.6719 4.89062C57.3594 6.48438 58.2031 9.29688 58.2031 13.3281V14.3594L51.125 16.375V9.20312Z" className='L1'/>
<path d="M73.7656 52L97.2969 11.3125H104.328L128.422 52H120.078L114.781 42.4844H86.6562L81.4531 52H73.7656ZM89.8906 36.625H111.5L100.672 17.2656L89.8906 36.625Z" className='L2'/>
<path d="M171.5 17.2656H148.297V30.1094H171.5C173.219 30.1094 174.406 29.8281 175.062 29.2656C175.75 28.6719 176.094 27.6719 176.094 26.2656V21.1094C176.094 19.7031 175.766 18.7188 175.109 18.1562C174.453 17.5625 173.25 17.2656 171.5 17.2656ZM140.609 52V11.3125H174.969C178.125 11.3125 180.391 11.9219 181.766 13.1406C183.172 14.3594 183.875 16.375 183.875 19.1875V28.2812C183.875 31.0312 183.172 33.0156 181.766 34.2344C180.391 35.4531 178.125 36.0625 174.969 36.0625H148.297V52H140.609Z" className='L3'/>
<path d="M206.281 52H198.641V11.4062H206.281V52Z"className='L4'/>
<path d="M247.625 9.20312V52H239.375V9.20312H216.734V2.45312H270.359V9.20312H247.625Z"className='L5'/>
<path d="M279.172 52V11.3125H320.141V17.3594H286.859V27.8125H306.922V33.8594H286.859V46.0469H320.516V52H279.172Z" className='L6'/>
<path d="M370.062 17.3594H341.656V45.8594H370.062V36.1562L377.75 37.6094V42.3906C377.75 46.1094 377.047 48.6406 375.641 49.9844C374.266 51.3281 371.641 52 367.766 52H343.859C339.984 52 337.359 51.3594 335.984 50.0781C334.641 48.7656 333.969 46.2031 333.969 42.3906V20.9688C333.969 17.1875 334.656 14.6406 336.031 13.3281C337.406 11.9844 340.016 11.3125 343.859 11.3125H367.766C371.641 11.3125 374.266 12 375.641 13.375C377.047 14.7188 377.75 17.25 377.75 20.9688V24.8125L370.062 26.0781V17.3594Z"className='L6'/>
</mask>
<path d="M51.125 9.20312H16.0625V23.0312H47.2812C51.6875 23.0312 54.7031 23.8438 56.3281 25.4688C57.9531 27.0938 58.7656 30.1719 58.7656 34.7031V40.2812C58.7656 44.8438 57.9688 47.9375 56.375 49.5625C54.7812 51.1875 51.75 52 47.2812 52H18.3594C13.8594 52 10.7969 51.1875 9.17188 49.5625C7.54688 47.9062 6.73438 44.8125 6.73438 40.2812V38.9219L14.4219 37.1406V45.1094H51.3125V30.2031H20.1875C15.6875 30.2031 12.6406 29.3906 11.0469 27.7656C9.45312 26.1094 8.65625 23.0156 8.65625 18.4844V14.1719C8.65625 9.64062 9.45312 6.5625 11.0469 4.9375C12.6406 3.28125 15.6875 2.45312 20.1875 2.45312H46.5781C50.9844 2.45312 54.0156 3.26562 55.6719 4.89062C57.3594 6.48438 58.2031 9.29688 58.2031 13.3281V14.3594L51.125 16.375V9.20312Z" fill="transparent" className='TestoSSS'/>
<path d="M73.7656 52L97.2969 11.3125H104.328L128.422 52H120.078L114.781 42.4844H86.6562L81.4531 52H73.7656ZM89.8906 36.625H111.5L100.672 17.2656L89.8906 36.625Z" fill="transparent" className='TestoSSS'/>
<path d="M171.5 17.2656H148.297V30.1094H171.5C173.219 30.1094 174.406 29.8281 175.062 29.2656C175.75 28.6719 176.094 27.6719 176.094 26.2656V21.1094C176.094 19.7031 175.766 18.7188 175.109 18.1562C174.453 17.5625 173.25 17.2656 171.5 17.2656ZM140.609 52V11.3125H174.969C178.125 11.3125 180.391 11.9219 181.766 13.1406C183.172 14.3594 183.875 16.375 183.875 19.1875V28.2812C183.875 31.0312 183.172 33.0156 181.766 34.2344C180.391 35.4531 178.125 36.0625 174.969 36.0625H148.297V52H140.609Z" fill="transparent" className='TestoSSS'/>
<path d="M206.281 52H198.641V11.4062H206.281V52Z" fill="transparent"className="TestoSSS"/>
<path d="M247.625 9.20312V52H239.375V9.20312H216.734V2.45312H270.359V9.20312H247.625Z" fill="transparent" className='TestoSSS'/>
<path d="M279.172 52V11.3125H320.141V17.3594H286.859V27.8125H306.922V33.8594H286.859V46.0469H320.516V52H279.172Z" fill="transparent" className='TestoSSS'/>
<path d="M370.062 17.3594H341.656V45.8594H370.062V36.1562L377.75 37.6094V42.3906C377.75 46.1094 377.047 48.6406 375.641 49.9844C374.266 51.3281 371.641 52 367.766 52H343.859C339.984 52 337.359 51.3594 335.984 50.0781C334.641 48.7656 333.969 46.2031 333.969 42.3906V20.9688C333.969 17.1875 334.656 14.6406 336.031 13.3281C337.406 11.9844 340.016 11.3125 343.859 11.3125H367.766C371.641 11.3125 374.266 12 375.641 13.375C377.047 14.7188 377.75 17.25 377.75 20.9688V24.8125L370.062 26.0781V17.3594Z" fill="transparent" className='TestoSSS'/>
<path d="M51.125 9.20312H16.0625V23.0312H47.2812C51.6875 23.0312 54.7031 23.8438 56.3281 25.4688C57.9531 27.0938 58.7656 30.1719 58.7656 34.7031V40.2812C58.7656 44.8438 57.9688 47.9375 56.375 49.5625C54.7812 51.1875 51.75 52 47.2812 52H18.3594C13.8594 52 10.7969 51.1875 9.17188 49.5625C7.54688 47.9062 6.73438 44.8125 6.73438 40.2812V38.9219L14.4219 37.1406V45.1094H51.3125V30.2031H20.1875C15.6875 30.2031 12.6406 29.3906 11.0469 27.7656C9.45312 26.1094 8.65625 23.0156 8.65625 18.4844V14.1719C8.65625 9.64062 9.45312 6.5625 11.0469 4.9375C12.6406 3.28125 15.6875 2.45312 20.1875 2.45312H46.5781C50.9844 2.45312 54.0156 3.26562 55.6719 4.89062C57.3594 6.48438 58.2031 9.29688 58.2031 13.3281V14.3594L51.125 16.375V9.20312Z" stroke="#FCF9F9" stroke-width="4" mask="url(#path-1-outside-1)"className='L1'/>
<path d="M73.7656 52L97.2969 11.3125H104.328L128.422 52H120.078L114.781 42.4844H86.6562L81.4531 52H73.7656ZM89.8906 36.625H111.5L100.672 17.2656L89.8906 36.625Z" stroke="#FCF9F9" stroke-width="4" mask="url(#path-1-outside-1)" className='L2'/>
<path d="M171.5 17.2656H148.297V30.1094H171.5C173.219 30.1094 174.406 29.8281 175.062 29.2656C175.75 28.6719 176.094 27.6719 176.094 26.2656V21.1094C176.094 19.7031 175.766 18.7188 175.109 18.1562C174.453 17.5625 173.25 17.2656 171.5 17.2656ZM140.609 52V11.3125H174.969C178.125 11.3125 180.391 11.9219 181.766 13.1406C183.172 14.3594 183.875 16.375 183.875 19.1875V28.2812C183.875 31.0312 183.172 33.0156 181.766 34.2344C180.391 35.4531 178.125 36.0625 174.969 36.0625H148.297V52H140.609Z" stroke="#FCF9F9" stroke-width="4" mask="url(#path-1-outside-1)"className='L3'/>
<path d="M206.281 52H198.641V11.4062H206.281V52Z" stroke="#FCF9F9" stroke-width="4" mask="url(#path-1-outside-1)" className='L4'/>
<path d="M247.625 9.20312V52H239.375V9.20312H216.734V2.45312H270.359V9.20312H247.625Z" stroke="#FCF9F9" stroke-width="4" mask="url(#path-1-outside-1)" className='L5'/>
<path d="M279.172 52V11.3125H320.141V17.3594H286.859V27.8125H306.922V33.8594H286.859V46.0469H320.516V52H279.172Z" stroke="#FCF9F9" stroke-width="4" mask="url(#path-1-outside-1)" className='L6'/>
<path d="M370.062 17.3594H341.656V45.8594H370.062V36.1562L377.75 37.6094V42.3906C377.75 46.1094 377.047 48.6406 375.641 49.9844C374.266 51.3281 371.641 52 367.766 52H343.859C339.984 52 337.359 51.3594 335.984 50.0781C334.641 48.7656 333.969 46.2031 333.969 42.3906V20.9688C333.969 17.1875 334.656 14.6406 336.031 13.3281C337.406 11.9844 340.016 11.3125 343.859 11.3125H367.766C371.641 11.3125 374.266 12 375.641 13.375C377.047 14.7188 377.75 17.25 377.75 20.9688V24.8125L370.062 26.0781V17.3594Z" stroke="#FCF9F9" stroke-width="4" mask="url(#path-1-outside-1)" className='L7'/>
</g>
<defs>
<filter id="filter0_d" x="0.734375" y="0.453125" width="383.016" height="61.5469" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
<feOffset dy="4"/>
<feGaussianBlur stdDeviation="2"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
</filter>
</defs>
</svg>
        </div>
    )
}
export default TextAnim;



