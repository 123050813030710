import React, { useEffect, useState } from 'react';
import {useWindowScroll} from 'react-use';
import '../../App.css';

const ScrollToTop = () => {
    const{y: pageYOffset} =useWindowScroll();
    const[visible, setVisibility] = useState(false);


    useEffect(() => {
        
    if (pageYOffset > 400)
        {setVisibility(true);}
    else
        {setVisibility(false);}},
    [pageYOffset]);

    const ScrollToTop = () => window.scrollTo({top: 0, behavior: 'smooth'})

    if(!visible){return false;}
    return(
        <div className='scroll-to-top cursor-pointer text-center' onClick={ScrollToTop}>
            <i className='iconfa-chevron-up'></i>
        </div>
    );
};

export default ScrollToTop;