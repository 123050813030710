import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useEffect, useState, useRef } from 'react';
import './App.css'; import 'aos/dist/aos.css';
import { Link } from 'react-router-dom'
import * as ReactBootStrap from 'react-bootstrap';
import 'react-slideshow-image/dist/styles.css'
import backgroundInnagine from './img/Albero.png'
import Fascia from "./img/bordo.png"
import Icona from "./img/ABC.png"
import ImmagineBackground5 from "./img/verticale-logo.png"
import ImmaginePor from "./img/logoPor.png"
import Youdompng from "./img/carosello2.png"
import logo from './img/sadsdsadda.png'
function Por() {
  const portaSu = () => {
    gotoCommentSection3()
  }  
  const commentSection3 = useRef(null);
  const gotoCommentSection3 = () => window.scrollTo({
    top: 0,
    behavior: "smooth"
  });
  const calc = (x, y) => [x - window.innerWidth / 3, y - window.innerHeight / 3]
  useEffect(() => {
    window.scrollTo(0, 0);
    updateDimensions();
    window.addEventListener("resize", updateDimensions.bind(this));
    // Specify how to clean up after this effect:
    return () => {
      window.removeEventListener("resize", updateDimensions.bind(this))
    }
  }, []);
  const [vwidth, setvwidth] = useState(0)
  const [vheight, setvheight] = useState(0)
  const updateDimensions = (e) => {
    let update_width = window.innerWidth;
    let update_height = window.innerHeight;
    setvwidth(update_width)
    setvheight(update_height)
  }
  const [offsetY, setOffsetY,] = useState(0);
  const handleScroll = () => setOffsetY(window.pageYOffset)
  useEffect(() => {
    window.addEventListener("scroll", handleScroll)
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])


  return (
    <div>
      <body style={{ height: "900px", width: "100%", position: "relative", marginTop: "0px", backgroundColor: "white" }} >
        {/*-------------------NAVBAR--------------- */}
        <nav>
          <div>
            <ReactBootStrap.Navbar collapseOnSelect expand="lg" style={{ backgroundColor: "#000535", width: "100%", position: "fixed" }} fixed="top">
              <Link onClick={portaSu} to='/' style={{ backgroundImage: `url(${logo})`, backgroundSize: "contain", backgroundRepeat: "no-repeat", height: "20px" }}><image Link className="logo"  ></image></Link>
              <ReactBootStrap.Navbar.Toggle aria-controls="responsive-navbar-nav" />
              <ReactBootStrap.Navbar.Collapse id="responsive-navbar-nav">
                <ReactBootStrap.Nav className="mr-auto">| 
                  <Link className='HomeT' to='/YouDom'>YouDom
                  < div style={{
                      marginTop: "4px", marginLeft: "2px", float: "right",
                      backgroundImage: `url(${Youdompng})`, backgroundSize: "contain", backgroundRepeat: "no-repeat", width: "20px", height: "20px"
                    }} />
                  </Link>
                  <Link className='HomeT'><a href="https://www.verticale.net/myazienda_scheda.php?dominio=sapitec.net&ragsoc=Sapitec">
                    < div style={{
                      marginTop: "-5px", marginLeft: "2px", float: "right",
                      backgroundImage: `url(${ImmagineBackground5})`, backgroundSize: "contain", backgroundRepeat: "no-repeat", width: "65px", height: "30px"
                    }} /></a>
                  </Link>
                  <Link className='HomeT' to='/Por'>
                    < div style={{
                      marginTop: "4px", marginLeft: "2px", float: "right",
                      backgroundImage: `url(${ImmaginePor})`, backgroundSize: "cover", backgroundRepeat: "no-repeat", width: "90px", height: "20px"
                    }} />
                  </Link>
                  <ReactBootStrap.NavDropdown.Divider />
                </ReactBootStrap.Nav>
              </ReactBootStrap.Navbar.Collapse>
            </ReactBootStrap.Navbar>
          </div>
        </nav>
        {/*-----------------SEZIONE 1--------------- */}
        <div style={{ width: "100%", height: "110%", textAlign: "center", backgroundImage: `url(${backgroundInnagine})`,backgroundRepeat:"repeat",marginRadius:"45px 45px"  }}>
          <div style={{borderColor:"black",borderRadius:"45px 45px 45px 45px ",width:"60%",height:"80%",float:"left", border:"8px solid  black",backgroundColor:"orange",marginLeft:"20%",marginTop:(vwidth > 1000?"5%":vwidth > 400?"15%":"20%"),borderBlock:"5px",borderColor:"black"}}>
            <div style={{ width: "100%", height: (vwidth > 560 ? "25%":"12%"), float: "left", marginTop: "0%", backgroundColor: "orange" ,borderRadius:"45px 45px" }}>
            <div style={{ backgroundImage: `url(${Icona})`,backgroundSize: "contain", width: "60%", height: "98%", float: "left", backgroundRepeat: "no-repeat", marginLeft: "20%" }}></div></div>
            <div style={{backgroundColor:"transparent",marginTop:(vwidth > 560 ? "20%":"2%"),height:"100%",width:"60%",marginLeft:"20%",fontSize:(vwidth > 1300?"1vw":vwidth > 800?"1.5vw":vwidth > 560?"2vw":"2.5vw"), height:"80%",fontFamily:"humanoid",fontWeight:"bold", textJustify: "inter-word"
                , textAlign: "justify"}}>Il progetto di Sapitec: una consulenza mirata alla progettazione di soluzioni innovative in ambito tecnologico<br/>Con il progetto presentato a valere sul bando 1.1.2 del POR FESR Regione del Veneto DGR 1966/2019, Sapitec srl ha l'obiettivo allargare la propria area di competenza sullo sviluppo di software per il trattamento dei dati di processo/automazione di macchinari, acquisendo le competenze e il Know How necessari per poter sviluppare prodotti compatibili per l'Industria 4.0, sempre più richiesti dal mercato. Per lo sviluppo di tali competenze, il progetto prevede un periodo di consulenza mirato alla realizzazione di un prototipo destinato al mondo industriale. Questo prototipo sarà progettato per essere il nodo centrale di futuri progetti. Le ricerche e le analisi saranno infatti indirizzate a creare un'architettura flessibile tecnologicamente avanzata, pensata per le esigenze legate al mondo dell'Industria 4.0. 
                Il progetto si avvale del finanziamento del suddetto fondo che copre il 40% dell'investimento totale. La spesa ammessa è pari a 28.080,00 € ed il contributo ammesso ammonta a 11.232,00 €</div></div>
          <div style={{ backgroundImage: `url(${Fascia})`, width: "2%", height: "100%", marginLeft: "1%" }} ></div>
        </div>
        {/*-----------------FOOTER--------------- */}
        <div style={{
          width: "100%", height: (vwidth > 1000 ? "15%" : "35%"), marginBottom: "0%",
          color: " rgb(144, 148, 148)", display: "flex", position: "relative", flexDirection: (vwidth > 1000 ? "row" : "column"), backgroundColor: "black"
        }} >
        <div style={{ float: "left", marginBottom: "0%", backgroundColor: "black", position: "absolute", width: "100%", textAlign: "center" }}><hr style={{ borderWidth: "0px", color: "white", width: "100%", height: "2px", backgroundColor: "white", marginTop: "0%" }}></hr>
          <a style={{ color: "white" }}>  <p className="copyright" style={{ marginTop: "0%", textAlign: "center" }}>
            &copy; {new Date().getFullYear()}{" "}
          Powered by {" "}
            <a href="http://sapitec.net">
              Sapitec  {" "}
            </a>| Telefono +39 0423 1950488  CF/PI 05084310266 - SDI. M5UXR1 - last update 10/04/2021
           </p> 
           </a>
        </div>
        </div>
      </body>
    </div>
  )
}
export default Por;